import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { FieldArray, useFormikContext } from "formik";
import { IconButton, withStyles, Radio } from "@material-ui/core";
import Clear from "@material-ui/icons/Clear";
import AddCircle from "@material-ui/icons/AddCircle";
import { get, set } from "dot-prop-immutable";
import { v4 } from "uuid";

import { Flex } from "../../../_common";
import MensageiroField from "./MensageiroField";

export const GET_DEFAULT_MENSAGEIRO = () => ({
  uid: v4(),
  tipo: { id: 1, descricao: "Appear.in" },
  principal: true,
});

function Mensageiros({ name, classes }) {
  const { values, setFieldValue } = useFormikContext();

  const mensageiros = get(values, name);

  useEffect(() => {
    if (!mensageiros || !mensageiros.length) {
      setFieldValue(name, [GET_DEFAULT_MENSAGEIRO()]);
    }
  }, []);

  useEffect(
    () => {
      const mensageiroPrincipal = mensageiros.find(({ principal }) => !!principal);
      if (!mensageiroPrincipal && mensageiros.length) {
        setFieldValue(`${name}[0]`, { ...mensageiros[0], principal: true });
      }
    },
    [mensageiros]
  );

  return (
    <FieldArray
      name={name}
      render={({ push, remove, form: { setValues, isSubmitting } }) => (
        <Fragment>
          {mensageiros.map(({ id, uid, principal }, index) => (
            <Flex key={id || uid} className={classes.row} justifyContent="space-between">
              <MensageiroField name={`${name}.${index}`} index={index} />

              <Flex justifyContent="flex-end" className={classes.actions}>
                <IconButton
                  className={mensageiros.length > 1 ? classes.showOnHover : classes.hidden}
                  onClick={() => remove(index)}
                  disabled={isSubmitting}
                >
                  <Clear />
                </IconButton>

                <IconButton
                  className={index + 1 < mensageiros.length ? classes.hidden : ""}
                  onClick={() =>
                    push({ uid: v4(), endereco: "", tipo: { id: 1, descricao: "Appear.in" }, principal: false })
                  }
                  disabled={isSubmitting}
                >
                  <AddCircle />
                </IconButton>

                <Radio
                  checked={principal}
                  onChange={() =>
                    setValues(
                      set(values, name, mensageiros.map((item, ind) => ({ ...item, principal: index === ind })))
                    )
                  }
                  disabled={isSubmitting}
                />
              </Flex>
            </Flex>
          ))}
        </Fragment>
      )}
    />
  );
}

Mensageiros.propTypes = {
  name: PropTypes.string,
  classes: PropTypes.object,
};

export default withStyles({
  row: { "&:hover $showOnHover": { visibility: "visible" }, margin: "16px 0" },
  actions: {
    minWidth: 144,
    width: 144,
    alignSelf: "flex-end",
  },
  showOnHover: { visibility: "hidden" },
  hidden: { visibility: "hidden" },
})(Mensageiros);
