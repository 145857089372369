import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Dialog } from "@material-ui/core";
import ThumbUp from "@material-ui/icons/ThumbUp";
import { useIntl } from "react-intl";
import ModalRecuperar from "./ModalRecuperar";
import { ActionButton } from "../../../../components";
import permissions from "../../../../_common/permissions";

Recuperar.propTypes = {
  callback: PropTypes.func,
  children: PropTypes.func,
  claim: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  leads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      grupos: PropTypes.arrayOf(PropTypes.number),
      preVendedorId: PropTypes.number,
      situacao: PropTypes.number,
    })
  ),
};

function Recuperar({ callback = x => x, children, leads = [], ...props }) {
  const [modalOpen, setModalOpen] = useState(false);
  if (leads.some(lead => lead.situacao === 1)) return null;
  const intl = useIntl();
  const close = response => {
    setModalOpen(false);
    callback(response);
  };

  return (
    <Fragment>
      <ActionButton
        label={intl.formatMessage({ defaultMessage: "Recuperar lead" })}
        id="btn-recuperar"
        type="icon"
        tooltip={intl.formatMessage({ defaultMessage: "Recuperar lead" })}
        icon={<ThumbUp />}
        onClick={() => setModalOpen(true)}
        claim={permissions.RECUPERAR}
        leads={leads}
        {...props}
      />
      <Dialog open={modalOpen} onClose={() => close(false)}>
        <ModalRecuperar leads={leads} {...props} callback={close} />
      </Dialog>
    </Fragment>
  );
}

export default Recuperar;
