import React, { useCallback, Fragment } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useIntl, defineMessage } from "react-intl";
import { DialogContent, DialogTitle, DialogActions, Button, DialogContentText } from "@material-ui/core";

import { AjaxBlackout, createSnackbar, createSnackbarAPIException } from "../../../../_common";

export async function reenviarEmailFeedback(ids) {
  AjaxBlackout.Show();
  try {
    const { data: response } = await axios.post("/api/pipeline/reuniao/reenviarFeedbacks", ids);
    createSnackbar(defineMessage({ defaultMessage: "E-mail enviado com sucesso" }));
    return response;
  } catch (err) {
    createSnackbarAPIException(err);
    return err;
  } finally {
    AjaxBlackout.Hide();
  }
}

function ModalReenviarEmailFeedback({ ids, callback = () => null }) {
  const intl = useIntl();
  const reenviar = useCallback(
    async () => {
      const response = await reenviarEmailFeedback(ids, callback);
      callback(response);
    },
    [ids]
  );

  return (
    <Fragment>
      <DialogTitle>{intl.formatMessage({ defaultMessage: "Reenviar email de feedback de reunião" })}</DialogTitle>
      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        <DialogContentText>
          {intl.formatMessage({
            defaultMessage: "Um email de feedback já foi enviado para este vendedor. Deseja enviar novamente?",
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => callback(false)}>{intl.formatMessage({ defaultMessage: "Cancelar" })}</Button>
        <Button onClick={reenviar} color="primary">
          {intl.formatMessage({ defaultMessage: "Reenviar" })}
        </Button>
      </DialogActions>
    </Fragment>
  );
}

ModalReenviarEmailFeedback.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  callback: PropTypes.func,
};

export default ModalReenviarEmailFeedback;
