import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { DialogTitle, DialogActions, DialogContent, Button, Divider, Typography, IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import Close from "@material-ui/icons/Close";

import { useIntl } from "react-intl";
import { Flex } from "../../../../_common";
import { formatPhoneMask } from "../../../../_common/utils/phone";

LeadsSemelhantes.propTypes = {
  leads: PropTypes.array,
  quantidade: PropTypes.number,
  onClose: PropTypes.func,
};

function LeadsSemelhantes({ onClose, leads = [], quantidade }) {
  const intl = useIntl();
  return (
    <Fragment>
      <DialogTitle>
        <Flex justifyContent="space-between">
          <Typography variant="h5">Leads semelhantes</Typography>
          <IconButton style={{ padding: 0 }} onClick={onClose}>
            <Close />
          </IconButton>
        </Flex>
      </DialogTitle>
      <DialogContent style={{ height: 328, width: 648, overflowY: "scroll", marginRight: 16 }}>
        {leads.map((lead, index) => (
          <Fragment key={lead.id}>
            {!!index && <Divider />}
            <Flex style={{ padding: "12px 0" }} flexDirection="column" justifyContent="center">
              <Link to={`/spotter/detalhes-lead/${lead.id}`} target="_blank">
                <Typography variant="h6">{lead.nome}</Typography>
              </Link>
              <Typography color="primary">{lead.site}</Typography>
              <Typography>
                {lead.telefone.map(telefone => (
                  <Typography key={telefone.id}>
                    {formatPhoneMask({ ddi: telefone.ddi, phone: telefone.numero })}
                  </Typography>
                ))}
              </Typography>
              <Typography>
                {intl.formatMessage({ defaultMessage: "Origem:" })} {lead.origem}
                <br />
                {intl.formatMessage({ defaultMessage: "Mercado:" })} {lead.mercado}
                <br />
                {intl.formatMessage({ defaultMessage: "Pré-vendedor:" })} {lead.preVendedor}
              </Typography>
              <br />
              {lead.contatos && (
                <>
                  <Typography variant="subtitle1">Contatos</Typography>
                  {lead.contatos.map(stringifyContato)}
                </>
              )}
            </Flex>
          </Fragment>
        ))}
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Typography color="textSecondary">
          {leads.length < quantidade && `Há outros ${quantidade - leads.length} leads semelhantes`}
        </Typography>
        <Button color="primary" onClick={onClose}>
          Ok
        </Button>
      </DialogActions>
    </Fragment>
  );
}

/* eslint-disable */
function stringifyContato({ telefones = [], nome = "", emails = [], telefone = "", email = "", ...contato }, index) {
  const nomeContato = nome ? nome : "";
  const emailContato = emails.length ? ` | ${emails.map(email => email.endereco).join(" | ")} ` : email ? email : "";
  const telefoneContato = telefones.length ? ` | ${telefones.map(telefone => telefone.numero).join(" | ")}` : telefone;
  const contatoString = `Contato ${index + 1}: ${nomeContato}${emailContato}${telefoneContato}`;

  return <Typography key={index}>{contatoString}</Typography>;
}
/* eslint-enable */

export default LeadsSemelhantes;
