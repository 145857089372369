import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Form, useFormikContext } from "formik";
import useSWR from "swr";
import { Button, DialogContent, DialogActions, withStyles, FormLabel } from "@material-ui/core";
import { usePermission } from "../../../../hooks";
import permissions from "../../../../_common/permissions";
import { FormikAsyncSelect } from "../../../../components/AsyncSelect";

function LeadInserirWorkflowForm({ onCancel, lead = {} }) {
  const intl = useIntl();
  const { isSubmitting, handleSubmit, touched, errors, setFieldValue } = useFormikContext();
  const temPermissao = usePermission(permissions.WORKFLOW);
  const { data: workflows, mutate: recarregarLista } = useSWR(`/Api/Pipeline/Workflow/Listar?leadId=${lead.id}`);
  const [workflowId, setWorkflowSelecionado] = useState("");

  const onChange = e => {
    setWorkflowSelecionado(e.target.value);
    setFieldValue("workflowId", e.target.value);
    setFieldValue("workflowName", e.currentTarget.innerText);
  };

  return (
    <>
      <DialogContent>
        <Form noValidate>
          <FormLabel error={touched.workflowId && !!errors.workflowId} required>
            {intl.formatMessage({ defaultMessage: "Gatilho" })}
          </FormLabel>
          <FormikAsyncSelect
            getInputLabel={item => item.descricao}
            id="workflowId"
            label={intl.formatMessage({ defaultMessage: "Selecione um Gatilho" })}
            name="workflowId"
            options={workflows}
            value={workflowId}
            onChange={e => onChange(e)}
            enableSearch
            criarNovo={temPermissao}
            urlCriarNovo={`/spotter/workflow`}
            fullWidth
            required
            carregarSomenteAoAbrir
            funcaoRecarregar={recarregarLista}
            desabilitarOpcoesPorRegra
            exibirRotuloInativo={false}
          />
        </Form>
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} onClick={onCancel}>
          {intl.formatMessage({ defaultMessage: "Cancelar" })}
        </Button>
        <Button disabled={isSubmitting} onClick={handleSubmit} color="primary">
          {intl.formatMessage({ defaultMessage: "Aplicar" })}
        </Button>
      </DialogActions>
    </>
  );
}

LeadInserirWorkflowForm.propTypes = {
  onCancel: PropTypes.func,
  lead: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default withStyles({})(LeadInserirWorkflowForm);
