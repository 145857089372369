import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FieldArray, useFormikContext } from "formik";
import { IconButton, Radio, withStyles } from "@material-ui/core";
import Clear from "@material-ui/icons/Clear";
import AddCircle from "@material-ui/icons/AddCircle";
import { get, set } from "dot-prop-immutable";
import { v4 } from "uuid";
import { DEFAULT_COUNTRY } from "../../../../_common/utils/phone/maskConfig";

import Telefone from "./Telefone";
import { Flex } from "../../../../_common";

export const GET_DEFAULT_TELEFONE = () => ({
  uid: v4(),
  tipo: { id: 1 },
  principal: true,
  ddi: DEFAULT_COUNTRY,
  linkMensagensTallos: "",
});

function Telefones({ name, leadId, requiredOne = false, deveBuscarSemelhantes = true, classes }) {
  const { values, setFieldValue } = useFormikContext();
  const telefones = get(values, name);

  React.useEffect(
    () => {
      function inserirTelefoneInicial() {
        if (!telefones || !telefones.length) {
          setFieldValue(name, [GET_DEFAULT_TELEFONE()]);
        }
      }
      inserirTelefoneInicial();
    },
    [telefones]
  );

  React.useEffect(
    () => {
      function garantirTelefonePrincipal() {
        const telPrincipal = telefones.find(({ principal }) => !!principal);
        if (!telPrincipal && telefones.length) {
          setFieldValue(`${name}[0]`, { ...telefones[0], principal: true });
        }
      }
      garantirTelefonePrincipal();
    },
    [telefones?.length]
  );

  return (
    <FieldArray
      name={name}
      render={({ push, remove, form: { setValues, isSubmitting } }) => (
        <Fragment>
          {telefones.map(({ id, uid, principal }, index) => (
            <Flex
              className={classes.row}
              justifyContent="space-between"
              data-scroll-anchor={`telefones.${index}`}
              key={id || uid}
            >
              <Flex className={classes.container} justifyContent="space-between">
                <Telefone
                  name={name}
                  index={index}
                  required={requiredOne && principal}
                  leadId={leadId}
                  deveBuscarSemelhantes={deveBuscarSemelhantes}
                />
              </Flex>
              <Flex justifyContent="flex-end" className={classes.actions}>
                <IconButton
                  className={telefones.length > 1 ? classes.showOnHover : classes.hidden}
                  onClick={() => remove(index)}
                  disabled={isSubmitting}
                >
                  <Clear />
                </IconButton>
                <IconButton
                  className={index + 1 < telefones.length ? classes.hidden : ""}
                  onClick={() => push({ uid: v4(), tipo: { id: 1 }, principal: false, ddi: "" })}
                  disabled={isSubmitting}
                >
                  <AddCircle />
                </IconButton>
                <Radio
                  checked={principal}
                  onChange={() =>
                    setValues(
                      set(values, name, telefones.map((telefone, ind) => ({ ...telefone, principal: index === ind })))
                    )
                  }
                  disabled={isSubmitting}
                />
              </Flex>
            </Flex>
          ))}
        </Fragment>
      )}
    />
  );
}

Telefones.propTypes = {
  name: PropTypes.string,
  requiredOne: PropTypes.bool,
  leadId: PropTypes.number,
  deveBuscarSemelhantes: PropTypes.bool,
  classes: PropTypes.object,
};

export default withStyles({
  container: {
    flexGrow: 1,
  },
  row: { "&:hover $showOnHover": { visibility: "visible" }, margin: "16px 0" },
  actions: {
    minWidth: 144,
    width: 144,
    alignSelf: "flex-end",
  },
  showOnHover: { visibility: "hidden" },
  hidden: { visibility: "hidden" },
})(Telefones);
