import React, { useState, useCallback } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import useSWR from "swr";
import { withStyles, FormLabel } from "@material-ui/core";
import { Formik } from "formik";
import axios from "axios";

import Produtos from "./Produtos";
import { useUsuario } from "../../../../hooks";
import { ModalEditProducts } from "../produtos/EditProducts";
import { CardOutline, NonCardOutline } from "../Outline";
import { SelectCicloLead, ActionButton } from "../../../../components";
import { Loading, Flex, createSnackbar, AjaxBlackout } from "../../../../_common";
import { currencyToNumber } from "../../../../_common/utils/currency";
import createSnackAPIException from "../../../../_common/utils/snackbar/createSnackbarAPIException";
import ProdutosTotal from "../produtos/ProdutosTotal";

ProdutosVendidosOutline.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  card: PropTypes.bool,
  lead: PropTypes.object,
  callbackValorTotal: PropTypes.func,
};

function ProdutosVendidosOutline({ id, card, lead, callbackValorTotal, classes }) {
  const intl = useIntl();
  const { featureToggles } = useUsuario();
  const [ciclo, setCiclo] = useState();
  const [cicloAtual, setCicloAtual] = useState();
  const [isOpenModalEdit, setIsOpenModalEdit] = useState(false);

  const changeCiclo = c => {
    if (c !== ciclo) {
      setCiclo(c);
    }
    if (!cicloAtual) {
      setCicloAtual(c);
    }
  };

  const { data: listaProdutos, mutate: mutateLista } = useSWR(`/api/pipeline/lead/produtos?id=${id}`);
  const { data: lista, isLoading, mutate } = useSWR(
    ciclo ? `/api/pipeline/produtoLead/produtosVendidos?id=${id}&ciclo=${ciclo}` : null
  );

  const salvar = async itens => {
    await axios.post(`/api/pipeline/lead/produtos?id=${id}`, { vendaId: lead.vendaId, produtos: itens });
  };

  const onSubmit = useCallback(async (values, helpers) => {
    const unformatted = values.produtos.map(({ desconto, valor, descricao, ...produto }) => ({
      ...produto,
      valor: typeof valor === "string" ? currencyToNumber(valor || "") : valor,
      desconto: typeof desconto === "string" ? currencyToNumber(desconto || "") : desconto,
    }));

    try {
      AjaxBlackout.Show();
      await salvar(unformatted);
      createSnackbar(intl.formatMessage({ defaultMessage: "Produtos salvos com sucesso." }));
      document.dispatchEvent(
        new CustomEvent("detalhe-lead/timeline/reloadLogFilterTriggers", { detail: { flag: true } })
      );
      setIsOpenModalEdit(false);
      helpers.resetForm({
        values,
      });
    } catch (err) {
      createSnackAPIException(err);
    } finally {
      AjaxBlackout.Hide();
      mutate();
      mutateLista();
    }
  });

  const onCloseModalEdit = useCallback(handleReset => {
    setIsOpenModalEdit(false);
    handleReset();
  });

  const Outline = card ? CardOutline : NonCardOutline;

  return (
    <Outline
      id="card-ProdutosVendidos"
      action={
        <Loading size={24} isLoading={isLoading} classes={{ loading: classes.loading }}>
          <>
            <FormLabel style={{ marginRight: 16 }}>{intl.formatMessage({ defaultMessage: "Ciclo" })}</FormLabel>
            <SelectCicloLead id={id} onChange={changeCiclo} />
          </>
        </Loading>
      }
      title={intl.formatMessage({ defaultMessage: "Produtos vendidos" })}
    >
      {isLoading && <Loading isLoading />}
      {!isLoading && (
        <>
          <Produtos produtos={lista} />
          <ProdutosTotal produtos={lista} callbackValorTotal={cicloAtual === ciclo ? callbackValorTotal : null} />
          <Flex flexDirection="column" alignItems="flex-start">
            {lead.vendido &&
              featureToggles.adicionarProdutosVendidos && (
                <>
                  <ActionButton
                    id="btn-adicionar-produto"
                    type="text"
                    color="primary"
                    label={intl.formatMessage({
                      defaultMessage: "Adicionar produto",
                    })}
                    onClick={() => setIsOpenModalEdit(true)}
                  />
                  <Formik
                    enableReinitialize
                    initialValues={{ produtos: listaProdutos }}
                    onSubmit={(values, helpers) => onSubmit(values, helpers)}
                  >
                    {({ handleSubmit, handleReset }) => (
                      <ModalEditProducts
                        onSubmit={handleSubmit}
                        onClose={() => onCloseModalEdit(handleReset)}
                        lead={lead}
                        isLoading={isLoading}
                        isOpen={isOpenModalEdit}
                        vendido={lead.vendido}
                      />
                    )}
                  </Formik>
                </>
              )}
          </Flex>
        </>
      )}
    </Outline>
  );
}

export default withStyles({
  loading: {
    height: "auto",
  },
})(ProdutosVendidosOutline);
