import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import useSWR from "swr";
import { DialogContent, DialogTitle, DialogActions, Button, DialogContentText, Grid } from "@material-ui/core";
import { useIntl } from "react-intl";
import { createSnackbar, createDialogConfirm, AjaxBlackout } from "../../../../_common";
import { useUsuario } from "../../../../hooks";
import AsyncSelect from "../../../../components/AsyncSelect";

function ModalRecuperar({ leads, callback = () => null }) {
  const ids = leads.map(lead => lead.id);
  const funisIds = [...new Set(leads.map(lead => lead.etapa.funil?.id ?? lead.etapa.funilId))];
  const intl = useIntl();
  const [etapaId, setEtapaId] = useState();

  const { data: podePularEtapas } = useSWR("/api/pipeline/funil/podePularEtapas");
  const { moverleadlivrementeentrefunis } = useUsuario();
  const possuiMultiplosFunis = moverleadlivrementeentrefunis || (funisIds.length === 1 && podePularEtapas);
  const { data: options, isValidating } = useSWR(
    () =>
      possuiMultiplosFunis
        ? `/api/pipeline/etapa/ListarParaRecuperar${funisIds.length === 1 ? `?funilId=${funisIds[0]}` : ""}`
        : null
  );

  async function recuperar() {
    if (possuiMultiplosFunis && (etapaId === undefined || etapaId === ""))
      createSnackbar(intl.formatMessage({ defaultMessage: "Selecione um funil para continuar" }));
    else {
      AjaxBlackout.Show();
      try {
        const filtroSalvo = JSON.parse(localStorage.getItem(`BuscaAvancadaSalva`));
        const recuperarDTO = { ids, etapaId, filtros: { filtros: filtroSalvo?.parametros } };
        const { data } = await axios.post(`/api/pipeline/lead/recuperar`, recuperarDTO);

        if (!data.success) throw Error("Ocorreu um erro");

        if (data.exibeMensagemProcessamento) {
          createDialogConfirm({
            title: intl.formatMessage({ defaultMessage: "Recuperação de leads" }),
            text: intl.formatMessage({
              defaultMessage:
                "Sua solicitação está em processamento e assim que concluída você será notificado por e-mail.",
            }),
            maxWidth: "sm",
            fullWidth: true,
            actions: unmount => (
              <DialogActions>
                <Button
                  color="primary"
                  onClick={() => {
                    unmount();
                    callback(true);
                  }}
                >
                  {intl.formatMessage({ defaultMessage: "OK" })}
                </Button>
              </DialogActions>
            ),
          });
        } else {
          createSnackbar(intl.formatMessage({ defaultMessage: "Operação realizada com sucesso" }));
          callback(true);
        }
      } catch (error) {
        createSnackbar(error.message);
        callback(false);
      } finally {
        AjaxBlackout.Hide();
      }
    }
  }

  return (
    <Fragment>
      <DialogTitle>{intl.formatMessage({ defaultMessage: "Recuperar lead" })}</DialogTitle>
      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        {possuiMultiplosFunis && options?.length > 1 ? (
          <>
            <DialogContentText>
              {intl.formatMessage({
                defaultMessage: "Selecione para qual funil o(s) lead(s) deve(m) ser recuperado(s)",
              })}
            </DialogContentText>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <AsyncSelect
                  fullWidth
                  label={intl.formatMessage({
                    defaultMessage: "Funil",
                  })}
                  value={etapaId}
                  onChange={param => setEtapaId(param.target.value)}
                  enableSearch
                  searchLabel={intl.formatMessage({ defaultMessage: "Pesquisar por funil" })}
                  type="singular"
                  options={options}
                  loading={isValidating}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <DialogContentText>
              {intl.formatMessage({
                defaultMessage: "Esse(s) lead(s) será(ão) recuperado(s) para etapa de entrada.",
              })}
            </DialogContentText>
            <DialogContentText>
              {intl.formatMessage({ defaultMessage: "Deseja recuperar esse(s) lead(s)?" })}
            </DialogContentText>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => callback(false)}>{intl.formatMessage({ defaultMessage: "Cancelar" })}</Button>
        <Button onClick={recuperar} color="primary">
          {intl.formatMessage({ defaultMessage: "Recuperar" })}
        </Button>
      </DialogActions>
    </Fragment>
  );
}

ModalRecuperar.propTypes = {
  leads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      etapa: PropTypes.shape({
        funil: PropTypes.object,
      }),
    })
  ),
  callback: PropTypes.func,
};

export default ModalRecuperar;
