import React from "react";
import PropTypes from "prop-types";
import SelectDDI from "./index";

function FormikSelectDDI({
  field: { onChange, value, onBlur, name },
  form: { isSubmitting, setFieldTouched },
  InputProps,
  ...props
}) {
  function handle(pValue) {
    onChange({ target: { name, value: pValue } }, name);
    onBlur({ target: { name, value: pValue } }, name);
    setTimeout(() => setFieldTouched(name, true));
  }
  return <SelectDDI value={value} disabled={isSubmitting} onChange={handle} {...InputProps} {...props} />;
}

FormikSelectDDI.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  InputProps: PropTypes.object,
};

export default FormikSelectDDI;
