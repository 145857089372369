import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import {
  withStyles,
  IconButton,
  CircularProgress,
  Button,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Avatar,
} from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import { Formik } from "formik";

import { Flex, Tipografia } from "../../../../_common";
import { maybeCallback } from "../../../../_common/utils/fp";
import { useEvent } from "../../../../hooks";

function Outline({
  loading,
  title,
  onSubmit,
  children,
  card,
  form: Form,
  classes,
  action,
  editButtonId,
  onCancel,
  disableEditEventName = "",
  CardProps = {},
  avatar = undefined,
  icone = undefined,
  forceEdit = false,
  alterarAvatar,
  isEditing = undefined,
  ...props
}) {
  const intl = useIntl();
  const [editting, setEditting] = useState(forceEdit);

  const handleEditingChange = value => {
    setEditting(value);
    if (isEditing !== undefined) isEditing(value);
  };

  // eslint-disable-next-line
  const Container = ({ children }) =>
    card ? (
      <Card className={classes.card} {...CardProps}>
        {children}
      </Card>
    ) : (
      <Flex className={classes.root} flexDirection="column">
        {children}
      </Flex>
    );

  // eslint-disable-next-line no-shadow
  const Content = ({ children }) => (card ? <CardContent>{children}</CardContent> : children);

  React.useEffect(
    () => {
      setEditting(forceEdit);
      if (isEditing !== undefined) isEditing(forceEdit);
    },
    [forceEdit, isEditing]
  );

  useEvent(disableEditEventName, () => {
    handleEditingChange(false);
  });

  return (
    <Container>
      {card ? (
        <CardHeader
          className={classes.cardHeader}
          avatar={
            avatar ??
            (icone ? (
              <Avatar
                sx={{ bgcolor: "red[500]" }}
                aria-label="recipe"
                src={icone}
                style={{ width: alterarAvatar ? "210px " : "40px", borderRadius: "0%" }}
              />
            ) : null)
          }
          disableTypography
          title={<Tipografia tipo="tituloCard">{title}</Tipografia>}
          action={
            action ||
            (Form &&
              !editting &&
              (!props.listaContatos || props.listaContatos?.length <= props.limiteEdicaoMultiplaContatos) && (
                <IconButton id={editButtonId} disabled={loading} onClick={() => handleEditingChange(true)}>
                  {loading ? <CircularProgress size={24} /> : <Edit />}
                </IconButton>
              ))
          }
        />
      ) : (
        <Flex className={classes.header} justifyContent="space-between" alignItems="center">
          <Tipografia className={classes.title} tipo="titulo2">
            {title}
          </Tipografia>
          {Form &&
            !editting &&
            (!props.listaContatos || props.listaContatos?.length <= props.limiteEdicaoMultiplaContatos) && (
              <IconButton id={editButtonId} disabled={loading} onClick={() => handleEditingChange(true)}>
                {loading ? <CircularProgress size={24} /> : <Edit />}
              </IconButton>
            )}
          {action && action}
        </Flex>
      )}
      <Content>
        {editting ? (
          <Formik
            {...props}
            enableReinitialize
            onSubmit={(f, b) => onSubmit(f, b).then(success => handleEditingChange(!success))}
            onReset={() => handleEditingChange(false)}
          >
            {bag => (
              <Flex flexDirection="column">
                <Form {...bag} {...props} />
                <Flex justifyContent="flex-end">
                  <CardActions>
                    <Button
                      id={`${editButtonId}--Salvar`}
                      disabled={bag.isSubmitting || bag.isValidating}
                      onClick={() => setTimeout(bag.handleSubmit, 200)}
                      color="primary"
                      style={{ width: 100 }}
                    >
                      {intl.formatMessage({ defaultMessage: "Salvar" })}
                      {bag.isSubmitting && <CircularProgress className={classes.saveSpinner} />}
                    </Button>

                    <Button
                      id={`${editButtonId}--Cancelar`}
                      disabled={bag.isSubmitting}
                      onClick={() => {
                        bag.handleReset();
                        maybeCallback(onCancel)();
                      }}
                    >
                      {intl.formatMessage({ defaultMessage: "Cancelar" })}
                    </Button>
                  </CardActions>
                </Flex>
              </Flex>
            )}
          </Formik>
        ) : (
          children
        )}
      </Content>
    </Container>
  );
}

Outline.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
  action: PropTypes.node,
  form: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  card: PropTypes.bool,
  editButtonId: PropTypes.string,
  CardProps: PropTypes.object,
  forceEdit: PropTypes.bool,
  onCancel: PropTypes.func,
  disableEditEventName: PropTypes.string,
  showDefaultSaveButton: PropTypes.bool,
  avatar: PropTypes.node,
  icone: PropTypes.string,
  alterarAvatar: PropTypes.bool,
  classes: PropTypes.object,
  isEditing: PropTypes.func,
  listaContatos: PropTypes.array,
  limiteEdicaoMultiplaContatos: PropTypes.number,
};

export default withStyles({
  root: {
    border: "1px solid lightgrey",
    borderRadius: 10,
    margin: 10,
    padding: 10,
  },
  header: {
    padding: "10px 20px 0",
  },
  title: {
    minHeight: 48,
    padding: "12px 0",
  },
  cardHeader: {
    backgroundColor: "#f7f7f7",
    boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2)",
    height: 56,
  },
  card: {
    marginBottom: 20,
    overflow: "visible",
  },
  saveSpinner: {
    marginLeft: 4,
    width: "18px !important",
    height: "18px !important",
    position: "absolute",
    right: 0,
  },
})(Outline);
