import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { countries } from "../../../../../_common/utils/phone/data";
import { CODE_DOMINICIAN_REPUBLIC } from "../../../../../_common/constantes";

function FlagDDI({ ddi, className, telefone }) {
  let indexddi = ddi;

  const ddiCode = (ddi + telefone).substring(0, 4);
  const codeDominicianRepublic = [
    CODE_DOMINICIAN_REPUBLIC.CODIGO_AREA_809,
    CODE_DOMINICIAN_REPUBLIC.CODIGO_AREA_829,
    CODE_DOMINICIAN_REPUBLIC.CODIGO_AREA_849,
  ];

  if (codeDominicianRepublic.includes(ddiCode)) indexddi = CODE_DOMINICIAN_REPUBLIC.CODIGO_AREA_809;

  const country = useMemo(() => countries[indexddi] ?? null, [indexddi]);
  return country ? <img className={className} src={country.flag} alt={country.name} /> : null;
}

FlagDDI.propTypes = {
  ddi: PropTypes.string,
  className: PropTypes.string,
  telefone: PropTypes.string,
};

export default FlagDDI;
