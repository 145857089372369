import React from "react";
import PropTypes from "prop-types";
import HelpIcon from "@material-ui/icons/Help";
import { withStyles, Tooltip } from "@material-ui/core";

import { useIntl } from "react-intl";
import { useGet, useTipoPlanoSpotter } from "../../../hooks";
import { Tipografia, Flex } from "../../../_common";
import Outline from "./Outline";

function Responsaveis({ id, classes = {} }) {
  const [responsaveis] = useGet(`/api/pipeline/lead/responsaveis?id=${id}`);
  const intl = useIntl();
  const possuiPvOriginal = responsaveis.preVendedorOriginal;
  const isSpotterFull = useTipoPlanoSpotter();

  return (
    <Outline
      card
      title={
        isSpotterFull
          ? intl.formatMessage({ defaultMessage: "Responsáveis" })
          : intl.formatMessage({ defaultMessage: "Responsável" })
      }
      id="card-Responsaveis"
    >
      <div id="responsaveis">
        <Flex className={classes.container} justifyContent="space-between">
          {possuiPvOriginal && isSpotterFull ? (
            <>
              <Tipografia>
                {intl.formatMessage({ defaultMessage: "Dono do Lead" })}
                <Tooltip
                  className={classes.tip}
                  classes={{ tooltip: classes.tooltip }}
                  title={intl.formatMessage({
                    defaultMessage: "Usuário responsável pelo lead até o agendamento.",
                  })}
                >
                  <HelpIcon color="action" className={classes.tooltipIcon} />
                </Tooltip>
              </Tipografia>
              <Tipografia id="id-responsavel-pre-vendedor">{responsaveis.preVendedorOriginal || "-"}</Tipografia>
            </>
          ) : (
            <>
              <Tipografia>
                {isSpotterFull
                  ? intl.formatMessage({ defaultMessage: "Pré-vendedor" })
                  : intl.formatMessage({ defaultMessage: "Usuário" })}
              </Tipografia>
              <Tipografia id="id-responsavel-pre-vendedor">{responsaveis.preVendedor || "-"}</Tipografia>
            </>
          )}
        </Flex>
        {possuiPvOriginal && isSpotterFull ? (
          <Flex className={classes.container} justifyContent="space-between">
            <Tipografia>
              {intl.formatMessage({ defaultMessage: "Responsável atual" })}
              <Tooltip
                className={classes.tip}
                classes={{ tooltip: classes.tooltip }}
                title={intl.formatMessage({
                  defaultMessage: "Usuário responsável pelo lead após o agendamento.",
                })}
              >
                <HelpIcon color="action" className={classes.tooltipIcon} />
              </Tooltip>
            </Tipografia>
            <Tipografia id="id-responsavel-vendedor">{responsaveis.preVendedor || "-"}</Tipografia>
          </Flex>
        ) : (
          isSpotterFull && (
            <Flex className={classes.container} justifyContent="space-between">
              <Tipografia>{intl.formatMessage({ defaultMessage: "Vendedor" })}</Tipografia>
              <Tipografia id="id-responsavel-vendedor">{responsaveis.vendedor || "-"}</Tipografia>
            </Flex>
          )
        )}

        {possuiPvOriginal &&
          isSpotterFull && (
            <Flex className={classes.container} justifyContent="space-between">
              <Tipografia>
                {intl.formatMessage({ defaultMessage: "Responsável pela reunião" })}
                <Tooltip
                  className={classes.tip}
                  classes={{ tooltip: classes.tooltip }}
                  title={intl.formatMessage({
                    defaultMessage: "Usuário responsável pela reunião com o lead.",
                  })}
                >
                  <HelpIcon color="action" className={classes.tooltipIcon} />
                </Tooltip>
              </Tipografia>
              <Tipografia id="id-responsavel-vendedor">{responsaveis.vendedor || "-"}</Tipografia>
            </Flex>
          )}
      </div>
    </Outline>
  );
}

Responsaveis.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  classes: PropTypes.object,
};

export default withStyles({
  container: {
    padding: "8px 20px",
    "&:hover": { backgroundColor: "rgb(247,247,247)" },
  },
  tooltip: {
    fontSize: 13,
  },
  tooltipIcon: {
    fontSize: 18,
  },
  tip: { position: "relative", top: 4, marginLeft: "10px" },
})(Responsaveis);
