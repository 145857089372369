import React from "react";
import PropTypes from "prop-types";
import { Dialog } from "@material-ui/core";
import { useIntl } from "react-intl";
import axios from "axios";
import SvgIcon from "@material-ui/core/SvgIcon";
import { useSnackbar } from "notistack";
import { useUsuario } from "../../../../hooks";
import concluirAgendamento from "../concluirAgendamento";
import MoveUp from "../../../../_common/icones/MoveUp";
import { ActionButton } from "../../../../components";
import ModalMudarDeFunil from "./ModalMudarDeFunil";
import { PERMISSAO_ETAPA, SITUACAO_REUNIAO, CONCLUIR_REUNIAO } from "../../../../_common/constantes";
import { AjaxBlackout, createSnackbar } from "../../../../_common";
import usePermissaoEtapa from "../../../base-leads/pipeline/components/usePermissaoEtapa";

MudarFunil.propTypes = {
  onTransfer: PropTypes.func,
  etapaLead: PropTypes.object,
  lead: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    grupos: PropTypes.arrayOf(PropTypes.number),
    preVendedorId: PropTypes.number,
    agendamento: PropTypes.object,
    etapa: PropTypes.shape({
      tipoGate: PropTypes.number,
      gateAnterior: PropTypes.number,
      id: PropTypes.number.isRequired,
      funilId: PropTypes.number,
      funil: PropTypes.object,
    }),
    reuniao: PropTypes.object,
    situacao: PropTypes.number,
  }),
  type: PropTypes.string,
  callback: PropTypes.func,
  moverLivremente: PropTypes.bool,
};

function MudarFunil({ moverLivremente, lead, etapaLead, onTransfer = x => x, callback = () => null, ...props }) {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { isGerente } = useUsuario();
  const permissaoNaEtapa = usePermissaoEtapa(lead.etapa);
  const hasPermission = isGerente || permissaoNaEtapa === PERMISSAO_ETAPA.INTERAGIR;

  const reuniaoId = lead.reuniao?.id || lead.agendamento?.id;
  const reuniaoSituacao = lead.reuniao?.situacao || lead.agendamento?.situacao;

  const [modalOpen, setModalOpen] = React.useState(false);

  function close(etapa) {
    if (!etapa) {
      setModalOpen(false);
    }
    // Verifica se possui reunião E se essa reunião NÃO está concluída E se essa reunião NÃO está cancelada
    else if (
      reuniaoId &&
      reuniaoSituacao !== SITUACAO_REUNIAO.CONCLUIDO &&
      reuniaoSituacao !== SITUACAO_REUNIAO.CANCELADA
    )
      concluirAgendamento(
        reuniaoId,
        lead.id,
        CONCLUIR_REUNIAO.MUDAR_FUNIL,
        intl.formatMessage({
          defaultMessage: "Para realizar a mudança de funil é necessário concluir a reunião atual. Deseja continuar?",
        }),
        () => mudarFunil(etapa)
      );
    else mudarFunil(etapa);
  }

  async function mudarFunil(etapa) {
    AjaxBlackout.Show();
    try {
      const { data } = await axios.post("/api/pipeline/lead/TransferirDeFunil", {
        id: lead.id,
        etapaOrigemId: lead.etapa.id,
        etapaDestinoId: etapa,
      });
      createSnackbar(intl.formatMessage({ defaultMessage: "Lead transferido com sucesso." }));
      callback(true);
      if (!data.success) throw data.messages;
      onTransfer(etapa);
      if (data.messages) {
        for (let i = 0; i < data.messages.length; i++) {
          enqueueSnackbar(data.messages[i], { variant: "Info" });
        }
      }
    } catch (err) {
      onTransfer(false);
      if (err && err.length) {
        for (let i = 0; i < err.length; i++) {
          enqueueSnackbar(err[i], { variant: "Info" });
        }
      }
    } finally {
      AjaxBlackout.Hide();
      setModalOpen(false);
    }
  }
  if (!hasPermission) return null;

  return (
    <>
      <ActionButton
        id="btnMudarFunil"
        type="text"
        icon={
          <SvgIcon style={{ transform: "rotate(90deg)" }}>
            <MoveUp type={props.type} />
          </SvgIcon>
        }
        variant="contained"
        color="primary"
        tooltip={intl.formatMessage({ defaultMessage: "Mudar de Funil" })}
        label={intl.formatMessage({ defaultMessage: "Mudar de Funil" })}
        onClick={() => setModalOpen(true)}
        {...props}
      />
      <Dialog open={modalOpen} onClose={() => close(false)}>
        <ModalMudarDeFunil moverLivremente={moverLivremente} lead={lead} onTransfer={close} {...props} />
      </Dialog>
    </>
  );
}

export default MudarFunil;
