import React, { useState } from "react";
import PropTypes from "prop-types";
import PlayArrow from "@material-ui/icons/PlayArrow";
import { useHistory } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";
import axios from "axios";
import { Dialog } from "@material-ui/core";

import { ActionButton } from "../../../../components";
import permissions from "../../../../_common/permissions";
import { useUsuario } from "../../../../hooks";
import { PERMISSAO_ETAPA, TIPO_GATE, TIPO_QUESTIONARIO } from "../../../../_common/constantes";
import ModalEscolherQuestionario from "./ModalEscolherQuestionario";
import { AjaxBlackout, createSnackbarAPIException } from "../../../../_common";
import usePermissaoEtapa from "../../../base-leads/pipeline/components/usePermissaoEtapa";

IniciarFiltro.propTypes = {
  useIconMenu: PropTypes.bool,
  useJustIcon: PropTypes.bool,
  useDisable: PropTypes.bool,
  lead: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    situacao: PropTypes.number,
    reuniao: PropTypes.shape({
      id: PropTypes.number,
      confirmacao: PropTypes.bool,
    }),
    agendamento: PropTypes.object,
    etapa: PropTypes.shape({
      id: PropTypes.number.isRequired,
      permissoes: PropTypes.arrayOf(PropTypes.shape({ tipoPerfil: PropTypes.number, permissao: PropTypes.number })),
      proximaEtapaId: PropTypes.number,
      questionario: PropTypes.shape({ id: PropTypes.number, tipo: PropTypes.number }),
      tipoGate: PropTypes.number,
      funil: PropTypes.object,
      funilId: PropTypes.number,
    }).isRequired,
  }).isRequired,
};

const messages = defineMessages({
  feedback: { defaultMessage: "Iniciar Feedback" },
  filtro: { defaultMessage: "Iniciar Filtro" },
});

export const feedbackLink = reuniaoId => `/spotter/feedback/${reuniaoId}`;
export const filterLink = (leadId, etapaId, questionarioId) =>
  `/spotter/questionario?id=${questionarioId}&leadId=${leadId}&etapaId=${etapaId}`;

function generateInfo(lead) {
  const reuniaoId = lead?.reuniao?.id ?? lead?.agendamento?.id;
  const isReuniao = reuniaoId && lead?.etapa?.questionario?.tipo === TIPO_QUESTIONARIO.FEEDBACK;
  let link;
  if (isReuniao) link = feedbackLink(reuniaoId);
  else link = filterLink(lead?.id, lead?.etapa?.id, lead?.etapa?.questionarioId || lead?.etapa?.questionario.id);

  return { reuniaoId, link, label: isReuniao ? messages.feedback : messages.filtro };
}

export default function IniciarFiltro({ lead, useIconMenu, useJustIcon = false, useDisable, ...props }) {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const permissaoNaEtapa = usePermissaoEtapa(lead?.etapa);
  const { isGerente } = useUsuario();
  const history = useHistory();
  const [questionariosPossiveis, setQuestionariosPossiveis] = useState(null);

  const hasPermission = isGerente || permissaoNaEtapa === PERMISSAO_ETAPA.INTERAGIR;
  if (!useDisable) {
    if (
      !useIconMenu &&
      (!lead?.etapa?.proximaEtapaId || !lead?.situacao || !hasPermission || lead?.etapa?.tipoGate !== TIPO_GATE.FILTRO)
    )
      return null;
  }
  const { reuniaoId, label } = generateInfo(lead);

  async function navigate() {
    AjaxBlackout.Show();
    try {
      const { data: questionarios } = await axios.get(`/api/pipeline/lead/BuscarQuestionariosPossiveis?id=${lead?.id}`);
      setQuestionariosPossiveis(questionarios);
      AjaxBlackout.Hide();
      if (questionarios.length === 1) goToFilter(questionarios[0].questionario.id, questionarios[0].tipoQuestionario);
      else setOpen(true);
    } catch (err) {
      createSnackbarAPIException(err);
      AjaxBlackout.Hide();
    }
  }

  function goToFilter(questionarioId, tipoQuestionario) {
    setOpen(false);
    if (!questionarioId) return;

    if (reuniaoId && tipoQuestionario === TIPO_QUESTIONARIO.FEEDBACK) {
      history.push({
        pathname: `/spotter/feedback/${lead?.reuniao?.id ?? lead?.agendamento?.id}`,
        state: { questionarioEscolhido: questionarioId },
      });
    } else {
      history.push(filterLink(lead?.id, lead?.etapa?.id, questionarioId));
    }
  }

  let claimNecessaria;
  if (lead?.etapa?.questionario?.tipo === TIPO_QUESTIONARIO.FEEDBACK) claimNecessaria = permissions.APLICAR_FEEDBACK;
  else if (lead?.etapa?.questionario?.tipo === TIPO_QUESTIONARIO.QUESTIONARIO_LEAD)
    claimNecessaria = permissions.APLICAR_FILTRO;

  return (
    <>
      <ActionButton
        claim={claimNecessaria}
        leads={lead}
        id="btnIniciarFiltro"
        type={useIconMenu && !useJustIcon ? "menu" : "icon"}
        icon={<PlayArrow fontSize="default" />}
        tooltip={intl.formatMessage(label)}
        label={!useJustIcon ? intl.formatMessage(label) : null}
        onClick={navigate}
        disabled={useDisable}
        {...props}
      />

      <Dialog open={open} onClose={() => setOpen(false)}>
        <ModalEscolherQuestionario
          leadId={lead?.id}
          onClose={goToFilter}
          questionariosPreCarregados={questionariosPossiveis}
        />
      </Dialog>
    </>
  );
}
