import React, { useContext } from "react";
import PropTypes from "prop-types";
import { TextField, Select } from "formik-material-ui";
import { Field, useFormikContext } from "formik";
import { CircularProgress, FormControl, InputLabel, MenuItem, withStyles } from "@material-ui/core";
import { get } from "lodash";

import { useIntl } from "react-intl";
import ListasContext from "./empresa/ListasContext";
import { Flex } from "../../../_common";
import { CAMPOS_LEADS_SEMELHANTES } from "../../../_common/constantes";
import { useLeadsSemelhantesBloqueados } from "../../../hooks";

MensageiroField.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number,
  campoSemelhantesBloqueados: PropTypes.oneOf(Object.keys(CAMPOS_LEADS_SEMELHANTES)),
};

function MensageiroField({
  name,
  index,
  classes,
  campoSemelhantesBloqueados = CAMPOS_LEADS_SEMELHANTES.CONTATO_MENSAGEIRO,
}) {
  const { values, validateField, errors } = useFormikContext();
  const { mensageiros: tipos } = useContext(ListasContext);
  const value = get(values, name);
  const [mensageiroPesquisa, setMensageiroPesquisa] = React.useState(get(values, name));
  const error = get(errors, `${name}.usuario`);

  const { data: temSemelhanteBloqueado, isValidating: loadingBloqueados } = useLeadsSemelhantesBloqueados(
    campoSemelhantesBloqueados,
    mensageiroPesquisa.usuario,
    mensageiroPesquisa.tipo?.id
  );

  React.useEffect(
    () => {
      setMensageiroPesquisa(value);
    },
    [value?.tipo?.id]
  );

  React.useEffect(
    () => {
      if (!loadingBloqueados) {
        validateField(`${name}.usuario`);
      }
    },
    [mensageiroPesquisa, loadingBloqueados, temSemelhanteBloqueado]
  );

  const validate = () => (temSemelhanteBloqueado ? "Contato bloqueado." : "");
  const intl = useIntl();

  return (
    <Flex className={classes.container} justifyContent="space-between">
      <FormControl className={classes.input}>
        <InputLabel>{intl.formatMessage({ defaultMessage: "Marcador" })}</InputLabel>
        <Field name={`${name}.tipo`} label="Marcador" component={Select} renderValue={({ descricao }) => descricao}>
          {tipos.map(tipo => (
            <MenuItem value={tipo} key={tipo.id}>
              {tipo.descricao}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
      <Field
        name={`${name}.usuario`}
        label={intl.formatMessage({ defaultMessage: "Comunicador {dataEntrada}" }, { dataEntrada: index + 1 })}
        component={TextField}
        className={classes.input}
        style={{ flexGrow: 1 }}
        validate={validate}
        error={!!error}
        InputLabelProps={{ error: !!error }}
        FormHelperTextProps={{ error: !!error }}
        helperText={error || undefined}
        InputProps={{
          endAdornment: loadingBloqueados ? <CircularProgress color="inherit" size={20} /> : undefined,
          onBlur: () => setMensageiroPesquisa(get(values, name)),
          error: !!error,
        }}
      />
    </Flex>
  );
}

export default withStyles({
  container: {
    flexGrow: 1,
  },
  input: {
    width: 144,
    "&:first-child": { marginRight: 24 },
  },
})(MensageiroField);
