import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Dialog } from "@material-ui/core";

import { useIntl } from "react-intl";
import ModalLeadsSemelhantes from "./ModalLeadsSemelhantes";

LeadsSemelhantes.propTypes = {
  onClose: PropTypes.func,
  classes: PropTypes.object,
};

function LeadsSemelhantes({ onClose = () => null, classes, ...props }) {
  const [open, setOpen] = useState(false);
  const intl = useIntl();

  function close() {
    setOpen(false);
    onClose();
  }

  return (
    <Fragment>
      <span style={{ cursor: "pointer", position: "absolute", width: "max-content" }} onClick={() => setOpen(true)}>
        {intl.formatMessage({
          defaultMessage: "Leads semelhantes encontrados. Clique para visualizar.",
        })}
      </span>
      <Dialog open={open} onClose={close}>
        <ModalLeadsSemelhantes {...props} onClose={close} />
      </Dialog>
    </Fragment>
  );
}

export default LeadsSemelhantes;
