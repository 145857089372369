import React from "react";
import PropTypes from "prop-types";
import { withStyles, Card, CardContent, CardActions, CardHeader } from "@material-ui/core";

import { Tipografia } from "../../../../_common";

CardOutline.propTypes = {
  classes: PropTypes.object,
  action: PropTypes.node,
  actions: PropTypes.node,
  title: PropTypes.string,
  children: PropTypes.node,
};

function CardOutline({ classes, title, children, action, actions }) {
  return (
    <Card className={classes.card}>
      <CardHeader
        className={classes.cardHeader}
        title={<Tipografia tipo="tituloCard">{title}</Tipografia>}
        disableTypography
        action={action}
      />

      <CardContent>{children}</CardContent>
      <CardActions>{actions}</CardActions>
    </Card>
  );
}

export default withStyles({
  cardHeader: {
    backgroundColor: "#f7f7f7",
    boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2)",
    height: 56,
  },
  card: {
    marginBottom: 20,
  },
})(CardOutline);
