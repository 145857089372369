import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import axios from "axios";
import moment from "moment";
import ContentLoader from "react-content-loader";
import {
  withStyles,
  Button,
  DialogTitle,
  DialogContent,
  TextField,
  CardActions,
  Table,
  TableHead,
  TableCell,
  Checkbox,
  TableSortLabel,
  TableRow,
  TableBody,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import { TIPO_QUESTIONARIO } from "../../../../_common/constantes";
import { AjaxBlackout, Flex, InfiniteScroll, createSnackbar, createSnackbarAPIException } from "../../../../_common";
import { useDebouncedState, useOrderBy, usePaginatedList } from "../../../../hooks";

function ModalReenviarMultiplosEmailsFeedback({ classes, cardId, funilId, callback = () => null }) {
  const intl = useIntl();
  const [debounced, setTexto, texto] = useDebouncedState("");
  const [orderBy, asc, sort] = useOrderBy("lead");
  const [args, setArgs] = useState({ orderBy, asc, texto: debounced });
  const [selected, setSelected] = useState([]);

  const onFetch = useCallback(
    ({ orderBy: order, asc: ascending, page: pag, texto: text }) =>
      axios
        .post(`/api/pipeline/reuniao/feedbacks?tipo=pendentes&cardId=${cardId}&funilId=${funilId}`, {
          text,
          quantidadePorPagina: 6,
          pagina: pag,
          ordenacao: { campo: order[0].toUpperCase() + order.slice(1), sentido: ascending ? "Asc" : "Desc" },
        })
        .then(({ data }) => data),
    [debounced, funilId]
  );

  const [content, fetch, loading, hasMore] = usePaginatedList(onFetch, args);

  const sendFeedbacks = selecionados => {
    AjaxBlackout.Show();
    return axios
      .post("/api/pipeline/reuniao/reenviarfeedbacks", selecionados)
      .then(response => {
        createSnackbar(intl.formatMessage({ defaultMessage: "Email reenviado" }));
        callback(response);
      })
      .catch(createSnackbarAPIException)
      .finally(AjaxBlackout.Hide);
  };

  useEffect(
    () => {
      setArgs({ orderBy, asc });
    },
    [orderBy, asc]
  );

  useEffect(
    () => {
      setArgs(a => ({ ...a, texto: debounced }));
    },
    [debounced]
  );

  return (
    <Fragment>
      <DialogTitle>{intl.formatMessage({ defaultMessage: "Feedbacks pendentes" })}</DialogTitle>
      <DialogContent>
        <Flex flexDirection="column" style={{ overflowY: "none" }}>
          <TextField
            label={intl.formatMessage({ defaultMessage: "Buscar Lead ou Vendedor" })}
            value={texto}
            onChange={e => setTexto(e.target.value)}
          />

          <div id="table-pendentes-container" className={classes.content}>
            <InfiniteScroll
              loadMore={() => fetch(false)}
              initialLoad={false}
              hasMore={hasMore}
              loader={null}
              useWindow={false}
              queryselector="#table-pendentes-container"
            >
              <Table className={classes.container}>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox" />
                    <TableCell onClick={() => sort("lead")}>
                      <TableSortLabel active={orderBy === "lead"} direction={asc ? "desc" : "asc"}>
                        {intl.formatMessage({ defaultMessage: "Lead" })}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell onClick={() => sort("vendedor")}>
                      <TableSortLabel active={orderBy === "vendedor"} direction={asc ? "desc" : "asc"}>
                        {intl.formatMessage({ defaultMessage: "Vendedor" })}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell onClick={() => sort("data")}>
                      <TableSortLabel active={orderBy === "data"} direction={asc ? "desc" : "asc"}>
                        {intl.formatMessage({ defaultMessage: "Data" })}
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {!content.length ? (
                    <TableRow>
                      <TableCell align="center" colSpan={4} rowSpan={4} className={classes.loader}>
                        {loading ? (
                          <Flex alignItems="center" justifyContent="center">
                            <CircularProgress size={80} />
                          </Flex>
                        ) : (
                          intl.formatMessage({ defaultMessage: "Não há itens para exibir" })
                        )}
                      </TableCell>
                    </TableRow>
                  ) : (
                    <React.Fragment>
                      {content.map(
                        feedback =>
                          feedback.etapa?.questionario?.tipo === TIPO_QUESTIONARIO.FEEDBACK && (
                            <TableRow hover key={feedback.lead.id}>
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={selected.includes(feedback.lead.id)}
                                  onChange={(e, checked) =>
                                    checked
                                      ? setSelected([...selected, feedback.lead.id])
                                      : setSelected(selected.filter(id => feedback.lead.id !== id))
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <Typography>{feedback.lead.nome}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{feedback.vendedor}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{moment(feedback.data).format("DD/MM/YYYY")}</Typography>
                              </TableCell>
                            </TableRow>
                          )
                      )}
                      {loading &&
                        [1, 2, 3].map(i => (
                          <TableRow key={i}>
                            <TableCell colSpan={4}>
                              <ContentLoader height={56} width={560}>
                                <rect x="0" y="0" rx="0" ry="0" height="100%" width="100%" />
                              </ContentLoader>
                            </TableCell>
                          </TableRow>
                        ))}
                    </React.Fragment>
                  )}
                </TableBody>
              </Table>
            </InfiniteScroll>
          </div>
        </Flex>
      </DialogContent>
      <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button id="salvar-reenviar-emails" onClick={() => callback()}>
          {intl.formatMessage({ defaultMessage: "Cancelar" })}
        </Button>
        <Button
          id="cancelar-reenviar-emails"
          color="primary"
          onClick={() => sendFeedbacks(selected)}
          disabled={!selected.length}
        >
          {intl.formatMessage({ defaultMessage: "Enviar" })}
        </Button>
      </CardActions>
    </Fragment>
  );
}

ModalReenviarMultiplosEmailsFeedback.propTypes = {
  callback: PropTypes.func,
  cardId: PropTypes.number,
  funilId: PropTypes.number,
};

export default withStyles({
  content: {
    maxHeight: "35vh",
    overflowY: "auto",
  },
  container: { marginTop: 24, flexDirection: "column" },
})(ModalReenviarMultiplosEmailsFeedback);
