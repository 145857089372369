import React from "react";
import PropTypes from "prop-types";
import { withStyles, Typography } from "@material-ui/core";

import { useIntl } from "react-intl";
import { Flex, Tipografia } from "../../../../_common";
import { formatCurrency } from "../../../../_common/utils/string";
import calculatePrice from "./calculatePrice";

Produtos.propTypes = {
  classes: PropTypes.object,
  produtos: PropTypes.array,
};

function Produtos({ classes, produtos = [] }) {
  const intl = useIntl();
  return produtos && produtos.length ? (
    produtos.map(produto => (
      <Flex key={produto.id} className={classes.container} justifyContent="space-between">
        <Tipografia>
          {produto.quantidade}x {produto.descricao}
        </Tipografia>
        {produto.valor && (
          <Typography color="textSecondary">
            {formatCurrency(calculatePrice(produto.valor, produto.desconto, produto.tipoDesconto, produto.quantidade))}
          </Typography>
        )}
        {produto.filtro && <Tipografia>{produto.filtro}</Tipografia>}
      </Flex>
    ))
  ) : (
    <Tipografia cor="darkSecondaryText" italico style={{ padding: 20 }}>
      {intl.formatMessage({ defaultMessage: "Nenhum produto vendido neste ciclo" })}
    </Tipografia>
  );
}

export default withStyles({
  container: {
    padding: "8px 20px",
    "&:hover": { backgroundColor: "rgb(247,247,247)" },
  },
})(Produtos);
