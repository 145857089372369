/* eslint-disable no-param-reassign */
import { DISCOUNT_TYPE } from "../../../../_common/constantes";

export default function(value = 0, discount = 0, type = 0, amount = 0) {
  let price = value * amount;
  if (type === DISCOUNT_TYPE.ABSOLUTE) price -= discount;
  else if (type === DISCOUNT_TYPE.PERCENT) price *= 1 - discount / 100;

  return price;
}
