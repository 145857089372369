import React, { Fragment } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import axios from "axios";
import { DialogContent, DialogTitle, DialogActions, Button, DialogContentText } from "@material-ui/core";
import { AjaxBlackout } from "../../../../_common";

function ModalReenviarDiagnostico({ ids, callback = () => null }) {
  const intl = useIntl();

  function excluir() {
    AjaxBlackout.Show();
    axios
      .post(`/api/pipeline/Reuniao/ReenvioEmailAgendamento?leadId=${ids}`)
      .then(callback)
      .catch(callback)
      .finally(() => AjaxBlackout.Hide());
  }

  return (
    <Fragment>
      <DialogTitle>{intl.formatMessage({ defaultMessage: "Reenviar diagnóstico do Lead" })}</DialogTitle>
      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        <DialogContentText>
          {intl.formatMessage({
            defaultMessage: "O diagnóstico do Lead já foi enviado para este vendedor. Deseja enviar novamente?",
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => callback(false)}>{intl.formatMessage({ defaultMessage: "Cancelar" })}</Button>
        <Button onClick={excluir} color="primary">
          {intl.formatMessage({ defaultMessage: "Reenviar" })}
        </Button>
      </DialogActions>
    </Fragment>
  );
}

ModalReenviarDiagnostico.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  callback: PropTypes.func,
};

export default ModalReenviarDiagnostico;
