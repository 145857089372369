import React, { Fragment, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Dialog } from "@material-ui/core";
import Send from "@material-ui/icons/Send";
import { Formik } from "formik";
import axios from "axios";
import Yup from "yup";

import { useIntl } from "react-intl";
import InserirCadenciaForm from "./Form";
import { ActionButton } from "../../../../components";
import permissions from "../../../../_common/permissions";
import { AjaxBlackout, createSnackbar, DialogTitleClose } from "../../../../_common";
import { maybeCallback } from "../../../../_common/utils/fp";

const INITIAL_VALUES = { campanhaId: "", contatos: [] };

async function agendar(dataToSave, callback, intl) {
  AjaxBlackout.Show();
  try {
    const { data } = await axios.post("/api/pipeline/lead/agendarCampanha", dataToSave);
    createSnackbar(intl.formatMessage({ defaultMessage: "Lead inserido na Campanha com sucesso!" }));
    maybeCallback(callback)(data);
  } catch (err) {
    // eslint-disable-next-line
    console.error(err);
    createSnackbar(intl.formatMessage({ defaultMessage: "Ocorreu um erro." }));
  } finally {
    AjaxBlackout.Hide();
  }
}

const validationSchema = Yup.object().shape({
  campanhaId: Yup.number().required("Campo obrigatório."),
  contatos: Yup.array(Yup.number()).required("Campo obrigatório."),
});

function LeadInserirCadencia({ callback, children, lead = {}, closeParentMenu, ActionButtonProps = {} }) {
  const [modalOpen, setModalOpen] = useState(false);
  const intl = useIntl();

  const onOpen = () => {
    setModalOpen(true);
    maybeCallback(closeParentMenu)();
  };

  const onClose = (response = false) => {
    setModalOpen(false);
    maybeCallback(callback)(response);
  };

  const onSubmit = useCallback(
    ({ campanhaId, contatos }) => agendar({ leadId: lead.id, campanhaId, contatos }, onClose, intl),
    [lead.id]
  );

  const actionButtonProps = {
    id: "btn-cadencia",
    type: "menu",
    label: intl.formatMessage({ defaultMessage: "Inserir na Cadência de E-mails" }),
    icon: <Send />,
    onClick: onOpen,
    claim: permissions.INSERIR_CADENCIA_MANUALMENTE,
    lead,
    ...ActionButtonProps,
  };

  return (
    <Fragment>
      {children ? children({ setModalOpen }) : <ActionButton {...actionButtonProps} />}
      <Dialog maxWidth="sm" fullWidth open={modalOpen} onClose={() => onClose()}>
        <DialogTitleClose onClose={() => onClose()}>
          {intl.formatMessage({ defaultMessage: "Inserir na Cadência de E-mails" })}
        </DialogTitleClose>
        <Formik
          initialValues={INITIAL_VALUES}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          <InserirCadenciaForm leadId={+lead.id} onClose={() => onClose()} />
        </Formik>
      </Dialog>
    </Fragment>
  );
}

LeadInserirCadencia.propTypes = {
  callback: PropTypes.func,
  children: PropTypes.func,
  filter: PropTypes.func,
  closeParentMenu: PropTypes.func,
  claim: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  lead: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    grupos: PropTypes.arrayOf(PropTypes.number),
    preVendedorId: PropTypes.number,
    situacao: PropTypes.number,
    etapa: PropTypes.shape({ posVenda: PropTypes.bool }),
  }),
  ActionButtonProps: PropTypes.object,
};

export default LeadInserirCadencia;
