import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import {
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  DialogContentText,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { AjaxBlackout, Flex, Loading } from "../../../../_common";

ModalReiniciarCiclo.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  callback: PropTypes.func,
  etapas: PropTypes.arrayOf(
    PropTypes.shape({
      funil: PropTypes.shape({
        id: PropTypes.number.isRequired,
        nome: PropTypes.string.isRequired,
      }).isRequired,
      nome: PropTypes.string.isRequired,
    })
  ),
  funilId: PropTypes.number,
};

export default function ModalReiniciarCiclo({ id, funilId, etapas, callback = () => null }) {
  const [funil, setFunil] = React.useState();
  const intl = useIntl();

  useEffect(
    () => {
      if (etapas && etapas.length === 1) {
        setFunil(etapas[0].funil.id.toString());
      }
    },
    [etapas]
  );

  async function reiniciarCiclo(funilIdDestino) {
    AjaxBlackout.Show();
    try {
      const { data: success } = await axios.post(
        `/api/pipeline/lead/reiniciarCiclo?id=${id}&funilId=${funilIdDestino}`
      );
      callback(success);
    } catch (e) {
      callback(false);
    } finally {
      AjaxBlackout.Hide();
    }
  }

  return (
    <Fragment>
      <DialogTitle>{intl.formatMessage({ defaultMessage: "Reiniciar ciclo do lead" })}</DialogTitle>
      <DialogContent>
        {etapas.length === 1 ? (
          <>
            <DialogContentText>
              {intl.formatMessage({
                defaultMessage:
                  "Ao reiniciar o ciclo do lead, seu ciclo será alterado e ele voltará à primeira etapa do funil",
              })}
            </DialogContentText>
            <DialogContentText>
              {intl.formatMessage({ defaultMessage: "Deseja reiniciar o ciclo do lead?" })}
            </DialogContentText>
          </>
        ) : (
          <>
            <DialogContentText>
              {intl.formatMessage({ defaultMessage: "Selecione a etapa destino do lead" })}
            </DialogContentText>
            <Loading isLoading={!etapas}>
              <div>
                {etapas.map(etapaDestino => (
                  <Flex justifyContent="flex-start" alignItems="center" key={etapaDestino.funil.id}>
                    <FormControlLabel
                      value={etapaDestino.funil.id?.toString()}
                      control={<Radio />}
                      label={
                        etapaDestino.funil.id === funilId
                          ? `${etapaDestino.funil.nome} - ${etapaDestino.nome} (${intl.formatMessage({
                              defaultMessage: "Atual",
                            })})`
                          : `${etapaDestino.funil.nome} - ${etapaDestino.nome}`
                      }
                      checked={funil === etapaDestino.funil.id.toString()}
                      onChange={event => setFunil(event.target.value)}
                    />
                  </Flex>
                ))}
              </div>
            </Loading>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => callback(false)} id="cancelar-reiniciar-ciclo">
          {intl.formatMessage({ defaultMessage: "Cancelar" })}
        </Button>
        <Button onClick={reiniciarCiclo.bind(this, funil)} color="primary" id="confirmar-reiniciar-ciclo">
          {intl.formatMessage({ defaultMessage: "Reiniciar ciclo" })}
        </Button>
      </DialogActions>
    </Fragment>
  );
}
