import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Dialog } from "@material-ui/core";
import LocalPharmacy from "@material-ui/icons/LocalPharmacy";

import { useIntl } from "react-intl";
import ModalReenviarDiagnostico from "./ModalReenviarDiagnostico";
import { ActionButton } from "../../../../components";
import permissions from "../../../../_common/permissions";
import { SITUACAO_REUNIAO } from "../../../../_common/constantes";

ReenviarDiagnostico.propTypes = {
  callback: PropTypes.func,
  claim: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  leads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      grupos: PropTypes.arrayOf(PropTypes.number),
      preVendedorId: PropTypes.number,
      reuniaoId: PropTypes.number,
    })
  ),
};

function ReenviarDiagnostico({ callback = () => null, leads = [], ...props }) {
  const intl = useIntl();
  const [modalOpen, setModalOpen] = useState(false);

  if (
    leads.some(
      lead =>
        !lead.reuniao ||
        (lead.reuniao &&
          (lead.reuniao.situacao !== SITUACAO_REUNIAO.VIGENTE && lead.reuniao.situacao !== SITUACAO_REUNIAO.REAGENDADA))
    )
  )
    return null;

  const close = response => {
    setModalOpen(false);
    callback(response);
  };

  return (
    <Fragment>
      <ActionButton
        id="btn-reenviar-diagnostico"
        onClick={() => setModalOpen(true)}
        type="menu"
        icon={<LocalPharmacy />}
        label={intl.formatMessage({ defaultMessage: "Reenviar diagnóstico" })}
        claim={permissions.REENVIAR_DIAGNOSTICO}
        leads={leads}
        {...props}
      />
      <Dialog open={modalOpen} onClose={() => close(false)}>
        <ModalReenviarDiagnostico ids={leads.map(lead => lead.id)} {...props} callback={close} />
      </Dialog>
    </Fragment>
  );
}

export default ReenviarDiagnostico;
