import React from "react";
import PropTypes from "prop-types";
import CallEnd from "@material-ui/icons/CallEnd";
import { useIntl } from "react-intl";
import broadcast, { eEvents } from "../../../../utils/broadcast";

import { ActionButton } from "../../../../components";

RegistrarLigacao.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  etapaId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nome: PropTypes.string,
  callback: PropTypes.func,
};

export default function RegistrarLigacao({ id, etapaId, nome, callback = () => null, ...props }) {
  function registrarLigacao() {
    callback();
    broadcast.dispatch(
      eEvents.ABRIR_MODAL_RESULTADO_LIGACAO,
      {
        detail: {
          leadId: id,
          CallerId: 0,
          resultadoLigacaoHabilitado: true,
          flRegistroManual: true,
          faseLeadId: etapaId,
        },
      },
      { propagate: false }
    );
  }

  const intl = useIntl();

  return (
    <ActionButton
      id="btn-registrar-ligacao"
      type="menu"
      icon={<CallEnd />}
      label={intl.formatMessage({ defaultMessage: "Registrar ligação" })}
      onClick={registrarLigacao}
      {...props}
    />
  );
}
