import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";

import { Flex, Tipografia } from "../../../../_common";

NonCardOutline.propTypes = {
  classes: PropTypes.object,
  action: PropTypes.node,
  title: PropTypes.string,
  children: PropTypes.node,
};

function NonCardOutline({ classes, title, children, action }) {
  return (
    <Flex className={classes.root} flexDirection="column">
      <Flex className={classes.header} justifyContent="space-between" alignItems="center">
        <Tipografia className={classes.title} tipo="titulo2">
          {title}
        </Tipografia>
        {action}
      </Flex>
      {children}
    </Flex>
  );
}

export default withStyles({
  root: {
    border: "1px solid lightgrey",
    borderRadius: 10,
    margin: 10,
    paddingBottom: 10,
  },
  header: {
    padding: "10px 20px 0",
  },
  title: {
    height: 48,
    padding: "12px 0",
  },
})(NonCardOutline);
