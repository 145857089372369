import styled, { css } from "styled-components";
import { List } from "@material-ui/core";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    margin-right: 3px;
    > img {
      width: 26px;
      max-height: 26px;
      box-shadow: 1px 1px 4px 1px rgb(0, 0, 0, 0.2);
    }
    > svg {
      color: #757575;
      margin-left: 2px;
      font-size: 20px;
    }
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.7;
      button {
        cursor: unset;
      }
    `};
`;

export const SCList = styled(List)`
  && {
    max-height: 200px;
    overflow-y: auto;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  cursor: pointer;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 380px;
  background: ${({ selected }) => (selected ? "#f2f2f2 !important" : "none")};
  img {
    width: 28px;
    max-height: 20px;
    box-shadow: 1px 1px 4px 1px rgb(0, 0, 0, 0.2);
  }
  b {
    font-weight: 500;
    margin: 0 10px;
  }
  > p {
    color: ${({ theme }) => theme.palette.text.darkSecondaryText};
    font-weight: 500;
    opacity: 0.8;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
  }

  &:hover {
    background: #f9f9f9;
  }
`;

export const SCListItem = styled(ListItem)`
  && {
    cursor: pointer;
    padding: 10px 12px;
    margin: 1px 0;
    display: flex;
    align-items: center;

    > p {
      margin: 0 0 0 5px;
      color: inherit;
      font-size: 12px;
      color: ${({ theme }) => theme.palette.text.darkSecondaryText};
    }

    &:hover {
      background: #f2f2f2;
    }
  }
`;
