import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import Loop from "@material-ui/icons/Loop";
import { Dialog } from "@material-ui/core";
import useSWR from "swr";
import { useIntl } from "react-intl";

import { ActionButton } from "../../../../components";
import PERMISSIONS from "../../../../_common/permissions";
import ModalReiniciarCiclo from "./ModalReiniciarCiclo";

ReiniciarCiclo.propTypes = {
  callback: PropTypes.func,
  claim: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  lead: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    etapa: PropTypes.shape({
      posVenda: PropTypes.bool.isRequired,
      funilId: PropTypes.number,
    }),
  }),
};

export default function ReiniciarCiclo({ callback = x => x, lead = {}, ...props }) {
  const intl = useIntl();
  const [modalOpen, setModalOpen] = useState(false);

  const { data: painel, mutate } = useSWR(`/api/pipeline/lead/painel?id=${lead.id}`, {
    fallbackData: lead?.etapa ? lead : undefined,
    revalidateOnMount: !lead?.etapa,
  });

  const close = response => {
    if (response) mutate(l => ({ ...l, etapa: { ...l.etapa, posVenda: false } }), false);
    setModalOpen(false);
    callback(response);
  };
  const url = "/api/pipeline/etapa/listarPrimeiras";
  const { data: etapas } = useSWR(() => url);

  if (!painel) return null;

  return (
    <Fragment>
      <ActionButton
        id="btn-reiniciar-ciclo"
        type="menu"
        onClick={() => setModalOpen(true)}
        icon={<Loop />}
        label={intl.formatMessage({ defaultMessage: "Reiniciar ciclo" })}
        tooltip={intl.formatMessage({
          defaultMessage: "Iniciar novo ciclo e enviar lead para primeira etapa do funil",
        })}
        leads={lead}
        claim={PERMISSIONS.REINICIAR_CICLO}
        {...props}
      />
      <Dialog disableBackdropClick open={modalOpen} onClose={() => close(false)}>
        <ModalReiniciarCiclo id={lead.id} funilId={lead.etapa.funilId} etapas={etapas} {...props} callback={close} />
      </Dialog>
    </Fragment>
  );
}
