import React, { Fragment, useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { Dialog, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";

import ModalReenviarMultiplosEmailsFeedback from "./ReenviarMultiplosEmailsFeedback";
import { ActionButton } from "../../../../components";

function ReenviarMultiplosEmailsFeedback({ callback = () => null, permissao, ...props }) {
  const intl = useIntl();
  const [modalOpen, setModalOpen] = useState(false);

  const close = response => {
    setModalOpen(false);
    callback(response);
  };

  return (
    <Fragment>
      <Tooltip
        title={
          permissao
            ? ""
            : intl.formatMessage({
                defaultMessage: "A empresa está configurada para não enviar e-mails de feedback.",
              })
        }
      >
        <span>
          <ActionButton
            type="text"
            color="primary"
            label={intl.formatMessage({ defaultMessage: "Reenviar E-mails de feedback" })}
            onClick={() => setModalOpen(true)}
            disabled={!permissao}
            {...props}
          />
        </span>
      </Tooltip>
      <Dialog open={modalOpen} onClose={() => close(false)} maxWidth="lg" fullWidth>
        <ModalReenviarMultiplosEmailsFeedback {...props} callback={close} />
      </Dialog>
    </Fragment>
  );
}

ReenviarMultiplosEmailsFeedback.propTypes = {
  callback: PropTypes.func,
  children: PropTypes.func,
  permissao: PropTypes.bool,
};

const mapStateToProps = state => ({
  permissao: state.usuario.enviarEmailFeedbackVendedor,
});

export default connect(
  mapStateToProps,
  {} // aqui passando um objeto vazio pro dispatch não vazar pro componente
)(ReenviarMultiplosEmailsFeedback);
