import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import {
  Button,
  DialogContent,
  DialogActions,
  Typography,
  withStyles,
  Dialog,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import axios from "axios";
import useSWR from "swr";
import { useIntl } from "react-intl";
import { createSnackbar, createDialogConfirm, AjaxBlackout } from "../../../../_common";
import createSnackAPIException from "../../../../_common/utils/snackbar/createSnackbarAPIException";
import AsyncSelect from "../../../../components/AsyncSelect";

function PulaEtapaModal({ ids, onCancel = x => x, onConfirm = x => x, filtros, classes }) {
  const intl = useIntl();
  const { data: options } = useSWR(`/api/pipeline/Etapa/ListarEtapasPularLote`);
  const [etapa, setEtapa] = useState(0);
  const [abrirModalCallback, setAbrirModalCallback] = useState(false);
  const [listaLeads, setListaLead] = useState([]);

  useEffect(
    () => {
      if (listaLeads.length > 0) setAbrirModalCallback(true);
      else setAbrirModalCallback(false);
    },
    [listaLeads, abrirModalCallback]
  );

  const setValor = async value => {
    setEtapa(value);
  };

  const dialogConfirmacao = () => {
    createDialogConfirm({
      title: intl.formatMessage({ defaultMessage: "Mover de etapa" }),
      acceptLabel: intl.formatMessage({ defaultMessage: "Mover" }),
      text: intl.formatMessage({
        defaultMessage: "Confirma mover o(s) lead(s) selecionado(s) de etapa?",
      }),
      tamanhoMaximo: "sm",
      callback: accepted => accepted && onSubmit(),
    });
  };

  async function onSubmit() {
    AjaxBlackout.Show();
    try {
      const retorno = await axios.post("/api/pipeline/Lead/PularLeadsEtapa", { etapaId: etapa, leadIds: ids, filtros });
      if (!retorno.data.success) throw Error(intl.formatMessage({ defaultMessage: "Ocorreu um erro." }));

      if (retorno.data.exibeMensagemProcessamento) {
        createDialogConfirm({
          title: intl.formatMessage({ defaultMessage: "Pular etapas" }),
          text: intl.formatMessage({
            defaultMessage:
              "Sua solicitação está em processamento e assim que concluída você será notificado por e-mail.",
          }),
          maxWidth: "sm",
          actions: unmount => (
            <DialogActions>
              <Button
                color="primary"
                onClick={() => {
                  unmount();
                  onCancel(true);
                }}
              >
                {intl.formatMessage({ defaultMessage: "OK" })}
              </Button>
            </DialogActions>
          ),
        });
      } else if (retorno.data.leads?.length > 0) {
        setListaLead(retorno.data.leads);
        setAbrirModalCallback(true);
      } else {
        createSnackbar(intl.formatMessage({ defaultMessage: "Operação realizada com sucesso." }));
        onConfirm(true);
      }
    } catch (err) {
      createSnackAPIException(err);
      onConfirm(false);
    } finally {
      AjaxBlackout.Hide();
    }
  }

  async function fecharDialogConfirmar() {
    setAbrirModalCallback(false);
    createSnackbar(intl.formatMessage({ defaultMessage: "Operação realizada com sucesso." }));
    onConfirm(true);
  }

  return (
    <>
      <Formik onReset={() => onCancel()} enableReinitialize>
        {({ isSubmitting, handleReset }) => (
          <Form>
            <DialogContent classes={{ root: classes.dialogContentRoot }}>
              <Typography>
                {intl.formatMessage({
                  defaultMessage: "Selecione a etapa para enviar o(s) lead(s)",
                })}
              </Typography>
              <AsyncSelect
                getId={item => item.id}
                getLabel={item => item.descricao}
                id="field-distribuicao-listaFiltro"
                label={intl.formatMessage({
                  defaultMessage: "Etapa",
                })}
                options={options}
                fullWidth
                value={etapa}
                className={classes.fieldTipo}
                onChange={e => setValor(e.target.value)}
                required
                enableSearch
              />
            </DialogContent>
            <DialogActions>
              <Button disabled={isSubmitting} onClick={handleReset}>
                {intl.formatMessage({ defaultMessage: "Cancelar" })}
              </Button>
              <Button disabled={isSubmitting} onClick={dialogConfirmacao} color="primary">
                {intl.formatMessage({ defaultMessage: "Confirmar" })}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
      <Dialog open={abrirModalCallback} fullWidth maxWidth="lg">
        <DialogContent id="1" classes={{ root: classes.dialogPrincipal }}>
          <DialogTitle> {intl.formatMessage({ defaultMessage: "Mover etapa" })}</DialogTitle>
          <DialogContent style={{ display: "flex", flexDirection: "column" }}>
            <DialogContentText>
              {intl.formatMessage({
                defaultMessage: "Não foi possível realizar a mudança de etapa do(s) lead(s) abaixo:",
              })}
            </DialogContentText>
          </DialogContent>
          {listaLeads.map((lead, index) => (
            <DialogContent key={index} style={{ paddingBottom: 4 }}>
              <DialogContentText classes={{ root: classes.dialogText }}>{lead.descricao}</DialogContentText>
            </DialogContent>
          ))}
          <DialogActions>
            <Button onClick={fecharDialogConfirmar} color="primary">
              {intl.formatMessage({ defaultMessage: "Ok" })}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

PulaEtapaModal.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  onCancel: PropTypes.func,
  filtros: PropTypes.object,
  onConfirm: PropTypes.func,
};

export default withStyles({
  dialogContentRoot: {
    overflowY: "visible",
  },
  dialogText: {
    color: "black !important",
    paddingLeft: 4,
  },
  dialogPrincipal: {
    paddingLeft: "5px !important",
    paddingRight: "5px !important",
    paddingBottom: "5px !important",
    paddingTop: "4px !important",
  },
})(PulaEtapaModal);
