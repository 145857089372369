import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { Form, Field, useFormikContext } from "formik";
import {
  Button,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  withStyles,
  FormHelperText,
  FormControl,
  FormGroup,
  FormLabel,
  Tooltip,
} from "@material-ui/core";
import axios from "axios";

import useSWR from "swr";
import { Tipografia, Loading, Flex } from "../../../../_common";
import { usePermissionSimple } from "../../../../hooks";
import permissions from "../../../../_common/permissions";
import { FormikAsyncSelect } from "../../../../components/AsyncSelect";

function LeadInserirCadenciaForm({ leadId, onClose, classes }) {
  const { isSubmitting, handleSubmit, touched, errors, values, setFieldValue } = useFormikContext();
  const [contatos, setContatos] = useState([]);
  const [isLoadingContatos, setIsLoadingContatos] = useState(true);
  const hasValidData = !!contatos?.length;

  const contatosHasError = touched.contatos && !!errors.contatos;
  const intl = useIntl();
  const temPermissao = usePermissionSimple(permissions.CADENCIA_EMAILS);
  const { data: campanhas, mutate: recarregarLista } = useSWR("/api/pipeline/Campanha/ListarIdDescricaoStatus");
  const [campanhaId, setCampanhaSelecionada] = useState("");

  const onChange = e => {
    if (e.target.value !== undefined) {
      setCampanhaSelecionada(e.target.value);
      carregaContatos(e.target.value);
      setFieldValue("campanhaId", e.target.value);
    }
  };

  async function carregaContatos(idCampanhaSelecionada) {
    setIsLoadingContatos(true);
    const { data } = await axios.get(
      `/api/pipeline/Campanha/ContatosDisponiveis?leadId=${leadId}&campanhaId=${idCampanhaSelecionada}`
    );
    setContatos(data || []);
    setFieldValue("contatos", []);
    setIsLoadingContatos(false);
  }

  return (
    <>
      <DialogContent>
        <div className={classes.campanhaWrapper}>
          <Flex>
            <Tipografia className={classes.avisoCampanha}>
              {intl.formatMessage({ defaultMessage: "São listadas todas as campanhas em andamento." })}
            </Tipografia>
          </Flex>
          <FormLabel error={touched.campanhaSelecionada && !!errors.campanhaSelecionada} required>
            {intl.formatMessage({ defaultMessage: "Campanha" })}
          </FormLabel>
          <FormikAsyncSelect
            getInputLabel={item => item.descricao}
            id="campanhaId"
            label={intl.formatMessage({ defaultMessage: "Selecione uma campanha" })}
            name="campanhaId"
            options={campanhas}
            value={values.campanhaId}
            onChange={e => onChange(e)}
            enableSearch
            criarNovo={temPermissao}
            urlCriarNovo={`/spotter/cadencia-emails/campanhas`}
            fullWidth
            carregarSomenteAoAbrir
            funcaoRecarregar={recarregarLista}
          />
        </div>

        {hasValidData &&
          campanhaId && (
            <Form noValidate>
              <FormControl error={contatosHasError} component="fieldset" required fullWidth>
                <FormLabel>{intl.formatMessage({ defaultMessage: "Contatos" })}</FormLabel>
                <Loading className={classes.loadingContatos} isLoading={isLoadingContatos} keepMounted size={32}>
                  <FormGroup>
                    {contatos.map(contato => (
                      <Tooltip
                        title={
                          !contato.emails[0].descadastro
                            ? ""
                            : "Contato não pode ser selecionado, por estar na lista de e-mails descadastrados."
                        }
                        disabled={!contato.emails[0].descadastro}
                        placement="top"
                        key={contato.id}
                      >
                        <FormControlLabel
                          name="contatos"
                          label={
                            <Flex flexDirection="column" style={{ lineHeight: 1 }}>
                              <span>
                                <Tipografia>{contato.nome}</Tipografia>
                                {!!contato.principal && (
                                  <Tipografia cor="darkSecondaryText">
                                    ({intl.formatMessage({ defaultMessage: "Principal" })})
                                  </Tipografia>
                                )}
                              </span>
                              <Tipografia tipo="notaLegendas" cor="darkSecondaryText">
                                {contato.emails[0].endereco}
                              </Tipografia>
                            </Flex>
                          }
                          control={
                            <Field
                              as={Checkbox}
                              value={String(contato.id)}
                              color="primary"
                              checked={values.contatos.includes(String(contato.id))}
                            />
                          }
                          disabled={isLoadingContatos || isSubmitting || contato.emails[0].descadastro}
                          key={contato.id}
                        />
                      </Tooltip>
                    ))}
                  </FormGroup>
                </Loading>
                {contatosHasError && <FormHelperText error>{errors.contatos}</FormHelperText>}
              </FormControl>
            </Form>
          )}

        {!contatos.length &&
          !isLoadingContatos && (
            <Tipografia tipo="navbar">
              {intl.formatMessage({
                defaultMessage: "Nenhum contato disponível para a campanha selecionada.",
              })}
            </Tipografia>
          )}
      </DialogContent>
      {!!hasValidData && (
        <DialogActions>
          <Button disabled={isSubmitting} onClick={() => onClose()}>
            {intl.formatMessage({ defaultMessage: "Cancelar" })}
          </Button>
          <Button disabled={isSubmitting || isLoadingContatos || !hasValidData} onClick={handleSubmit} color="primary">
            {intl.formatMessage({ defaultMessage: "Inserir" })}
          </Button>
        </DialogActions>
      )}

      {!hasValidData && (
        <DialogActions>
          <Button onClick={() => onClose()}>{intl.formatMessage({ defaultMessage: "Fechar" })}</Button>
        </DialogActions>
      )}
    </>
  );
}

LeadInserirCadenciaForm.propTypes = {
  leadId: PropTypes.number,
  onClose: PropTypes.func,
};

export default withStyles({
  campanhaWrapper: {
    marginBottom: 24,
  },
  loadingContatos: {
    height: "auto !important",
  },
  avisoCampanha: {
    opacity: 0.5,
    marginBottom: 15,
  },
})(LeadInserirCadenciaForm);
