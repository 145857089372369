import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, withStyles } from "@material-ui/core";
import { useIntl } from "react-intl";

import PulaEtapaModal from "./Modal";
import { ActionButton } from "../../../../components";
import { DialogTitleClose, createSnackbar } from "../../../../_common";
import permissions from "../../../../_common/permissions";

function PulaEtapa({ callback = x => x, children, leads, classes, ...props }) {
  const ids = leads.map(({ id }) => id);
  const intl = useIntl();
  const [modalOpen, setModalOpen] = useState(false);

  const close = response => {
    setModalOpen(false);
    callback(response);
  };

  const open = () => {
    if (ids.length > 100)
      createSnackbar(intl.formatMessage({ defaultMessage: "Selecione no máximo 100 Leads para mover em lote." }));
    else setModalOpen(true);
  };

  return (
    <Fragment>
      <ActionButton
        id="btn-pular-etapa"
        type="text"
        label={intl.formatMessage({
          defaultMessage: "Mover Etapa",
        })}
        tooltip={intl.formatMessage({
          defaultMessage: "Mover Etapa",
        })}
        onClick={open}
        style={{ color: "white" }}
        claim={permissions.MOVER_LEADS_LOTE}
        leads={leads}
        {...props}
      />
      {modalOpen && (
        <Dialog
          open={modalOpen}
          onClose={() => close(false)}
          maxWidth="sm"
          fullWidth
          classes={{ paper: classes.paper }}
        >
          <DialogTitleClose onClose={() => close(false)}>
            {intl.formatMessage({ defaultMessage: "Mover Etapa" })}
          </DialogTitleClose>
          <PulaEtapaModal ids={ids} onCancel={close} onConfirm={close} {...props} />
        </Dialog>
      )}
    </Fragment>
  );
}

PulaEtapa.propTypes = {
  callback: PropTypes.func,
  children: PropTypes.func,
  leads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      grupos: PropTypes.arrayOf(PropTypes.number),
      preVendedorId: PropTypes.number,
      situacao: PropTypes.number,
      etapa: PropTypes.shape({ posVenda: PropTypes.bool }),
    })
  ),
};

export default withStyles({
  paper: {
    overflowY: "visible",
  },
})(PulaEtapa);
