import React from "react";
import useSWR from "swr";
import PropTypes from "prop-types";
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button, withStyles } from "@material-ui/core";
import { useIntl } from "react-intl";
import { Loading } from "../../../../_common";
import AsyncSelect from "../../../../components/AsyncSelect";

ModalMudarDeFunil.propTypes = {
  onTransfer: PropTypes.func.isRequired,
  classes: PropTypes.object,
  lead: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    etapa: PropTypes.shape({
      funilId: PropTypes.number,
    }),
  }),
  moverLivremente: PropTypes.bool,
};

function ModalMudarDeFunil({ moverLivremente, lead, onTransfer, classes }, props) {
  const [etapa, setEtapa] = React.useState();
  const intl = useIntl();
  const { data: funis, isValidating, mutate } = useSWR(
    () =>
      moverLivremente
        ? `/api/pipeline/funil/listarComEtapas?leadId=${lead.id}`
        : `/api/pipeline/etapa/listarPrimeiras?leadId=${lead?.id}&funilId=${lead?.etapa?.funilId}`
  );

  const etapas = [];
  if (!isValidating)
    if (moverLivremente)
      funis.forEach(f => {
        f.etapas.filter(e => !e.posVenda).map(e => etapas.push({ id: e.id, nome: `${f.nome} - ${e.nome}` }));
      });
    else funis.map(e => etapas.push({ id: e.id, nome: `${e.nome} - ${e.funil.nome}` }));

  React.useEffect(() => {
    mutate();
  }, []);

  return (
    <>
      <DialogTitle> {intl.formatMessage({ defaultMessage: "Mudar de Funil" })}</DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText className={classes.contentText}>
          {funis?.length === 0
            ? intl.formatMessage({ defaultMessage: "Não há opções de funis disponíveis" })
            : intl.formatMessage({ defaultMessage: "Selecione a etapa destino do lead" })}
        </DialogContentText>
        <Loading isLoading={isValidating} className={classes.loading}>
          {!isValidating && (
            <div className={classes.container}>
              <AsyncSelect
                id="select-etapa"
                getLabel={item => item.nome}
                label={intl.formatMessage({ defaultMessage: "Etapa" })}
                enableSearch
                fullWidth
                onChange={event => setEtapa(event.target.value)}
                options={etapas}
                {...props}
                value={etapa}
              />
            </div>
          )}
        </Loading>
      </DialogContent>
      <DialogActions>
        <Button id="btnCancelar-ModalMudarDeFunil" onClick={() => onTransfer()}>
          {intl.formatMessage({ defaultMessage: "Cancelar" })}
        </Button>
        <Button id="btnMover-ModalMudarDeFunil" color="primary" onClick={() => onTransfer(etapa)} disabled={!etapa}>
          {intl.formatMessage({ defaultMessage: "Mover" })}
        </Button>
      </DialogActions>
    </>
  );
}

export default withStyles(() => ({
  content: {
    paddingLeft: 0,
  },
  contentText: {
    paddingLeft: 24,
    marginBottom: 12,
  },
  container: {
    paddingLeft: 24,
  },
  loading: {
    maxHeight: "100px",
  },
}))(ModalMudarDeFunil);
