import React from "react";
import PropTypes from "prop-types";
import PhoneIcon from "@material-ui/icons/Phone";

import { useIntl } from "react-intl";
import permissions from "../../../../_common/permissions";
import { ActionButton } from "../../../../components";

function Ligar({ id, useIconMenu, setModalLigacaoIsOpen, ...props }) {
  const intl = useIntl();

  const handleClickPhoneForwarded = () => {
    setModalLigacaoIsOpen(true);
  };

  return (
    <>
      <ActionButton
        claim={permissions.EFETUAR_LIGACOES}
        id="btn-ligar"
        onClick={() => handleClickPhoneForwarded()}
        {...props}
        type={useIconMenu ? "menu" : "text"}
        icon={<PhoneIcon />}
        label={intl.formatMessage({ defaultMessage: "Ligar" })}
        color="primary"
      />
    </>
  );
}

Ligar.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  useIconMenu: PropTypes.bool,
  setModalLigacaoIsOpen: PropTypes.func,
};

export default Ligar;
