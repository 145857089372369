import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Dialog } from "@material-ui/core";
import SwapCallsIcon from "@material-ui/icons/SwapCalls";
import { Formik } from "formik";
import axios from "axios";
import Yup from "yup";
import { useIntl } from "react-intl";
import InserirWorkflowForm from "./Form";
import { ActionButton } from "../../../../components";
import permissions from "../../../../_common/permissions";
import { AjaxBlackout, createSnackbar, DialogTitleClose } from "../../../../_common";
import { maybeCallback } from "../../../../_common/utils/fp";

const INITIAL_VALUES = { workflowId: "" };

async function inserir(dataToSave, onClose, intl) {
  AjaxBlackout.Show();
  try {
    await axios.post("/Api/Pipeline/Workflow/InserirLead", dataToSave);
    createSnackbar(
      intl.formatMessage({ defaultMessage: "Inserido no workflow {nome} com sucesso!" }, { nome: dataToSave.nome })
    );
    maybeCallback(onClose)(true);
  } catch (err) {
    // eslint-disable-next-line
    console.error(err);
    createSnackbar(intl.formatMessage({ defaultMessage: "Ocorreu um erro." }));
  } finally {
    AjaxBlackout.Hide();
  }
}

function LeadInserirWorkflow({ callback = x => x, children, lead = {}, closeParentMenu, ...props }) {
  const [modalOpen, setModalOpen] = useState(false);
  const intl = useIntl();

  const validationSchema = Yup.object().shape({
    workflowId: Yup.string().required(intl.formatMessage({ defaultMessage: "Campo obrigatório." })),
  });

  const onOpen = () => {
    setModalOpen(true);
    maybeCallback(closeParentMenu)();
  };

  const onClose = (response = false) => {
    setModalOpen(false);
    maybeCallback(callback)(response);
  };

  const onSubmit = ({ workflowId, workflowName }) => {
    const dataToSave = {
      leadId: lead.id,
      workflowId,
      nome: workflowName,
    };

    inserir(dataToSave, onClose, intl);
  };

  return (
    <Fragment>
      {children ? (
        children({ setModalOpen })
      ) : (
        <ActionButton
          id="btn-inserir-workflow"
          type="menu"
          label={intl.formatMessage({ defaultMessage: "Inserir em um Workflow" })}
          icon={<SwapCallsIcon />}
          onClick={onOpen}
          claim={permissions.INSERIR_WORKFLOW_MANUALMENTE}
          lead={lead}
          {...props}
        />
      )}
      <Dialog maxWidth="sm" fullWidth open={modalOpen} onClose={() => onClose()}>
        <DialogTitleClose onClose={() => onClose()}>
          {intl.formatMessage({ defaultMessage: "Inserir em um Workflow" })}
        </DialogTitleClose>
        <Formik
          initialValues={INITIAL_VALUES}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          <InserirWorkflowForm lead={lead} onCancel={() => onClose()} />
        </Formik>
      </Dialog>
    </Fragment>
  );
}

LeadInserirWorkflow.propTypes = {
  callback: PropTypes.func,
  children: PropTypes.func,
  closeParentMenu: PropTypes.func,
  lead: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default LeadInserirWorkflow;
