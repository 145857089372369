import React, { useState, useRef, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { Popover } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { countries } from "../../../../../_common/utils/phone/data";
import { DEFAULT_COUNTRY } from "../../../../../_common/utils/phone/maskConfig";
import { Container, SCList, SCListItem } from "./styles";
import { storage } from "../../../../../utils/storage";
import { CODE_DOMINICIAN_REPUBLIC } from "../../../../../_common/constantes";

const storageKey = "countries.last_used";

function SelectDDI({ value, onChange, onBlur, disabled, className, ...props }) {
  let ddValue = value;
  if (props.inputProps?.numero) {
    if (value === "1") {
      const ddiCode = (value + props.inputProps.numero)
        .replace(/\D/g, "")
        .trim()
        .substring(0, 4);
      const codeDominicianRepublic = [
        CODE_DOMINICIAN_REPUBLIC.CODIGO_AREA_809,
        CODE_DOMINICIAN_REPUBLIC.CODIGO_AREA_829,
        CODE_DOMINICIAN_REPUBLIC.CODIGO_AREA_849,
      ];

      if (codeDominicianRepublic.includes(ddiCode)) ddValue = CODE_DOMINICIAN_REPUBLIC.CODIGO_AREA_809;
      else ddValue = value;
    } else ddValue = value;
  }

  const [open, setOpen] = useState(false);
  const containerRef = useRef(null);

  const onSelect = useCallback(callingCode => {
    const lastUsed = storage.get(storageKey, []);
    lastUsed.unshift(callingCode);
    storage.set(storageKey, [...new Set(lastUsed.splice(0, 6))]);
    setOpen(false);
    onChange(callingCode);
    if (onBlur) onBlur(callingCode);
  }, []);

  const countriesList = useMemo(() => {
    const lastUsed = {};
    storage
      .get(storageKey, [DEFAULT_COUNTRY])
      .reverse()
      .forEach((x, i) => {
        lastUsed[x] = i + 1;
      });

    return Object.values(countries)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(x => ({ ...x, pos: lastUsed[x.callingCode] ? lastUsed[x.callingCode] : 0 }))
      .sort((a, b) => b.pos - a.pos);
  }, []);

  const selected = useMemo(
    () => {
      let country = countries[ddValue];
      if (!country) {
        country = countries[DEFAULT_COUNTRY];
        onChange(country.callingCode);
      }
      return country;
    },
    [ddValue]
  );

  return (
    <Container ref={containerRef} disabled={disabled} className={className}>
      <button
        type="button"
        onClick={() => {
          if (disabled) return;
          setOpen(true);
        }}
      >
        <img src={selected.flag} alt={selected.name} />
        <ArrowDropDownIcon />
      </button>

      <Popover
        open={open}
        anchorEl={containerRef?.current}
        anchorOrigin={{ horizontal: "center", vertical: "center" }}
        onClose={() => {
          setOpen(false);
          if (onBlur) onBlur(value);
        }}
      >
        <SCList component="nav" id="listaPaises">
          {countriesList?.map(c => (
            <SCListItem
              key={c.name}
              selected={c.callingCode === value}
              onClick={() => {
                onSelect(c.callingCode);
              }}
            >
              <img src={c.flag} alt={c.alpha2Code} />
              <b>{c.name}</b>
              <p>+{c.callingCode}</p>
            </SCListItem>
          ))}
        </SCList>
      </Popover>
    </Container>
  );
}

SelectDDI.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  props: PropTypes.object,
  inputProps: PropTypes.object,
  numero: PropTypes.string,
};

export default SelectDDI;
