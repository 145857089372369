import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import useSWR from "swr";
import { Grid, Button, withStyles } from "@material-ui/core";
import Outline from "../Outline";
import MentorIA from "./MentorIA";
import iconeCarregando from "../../../../_common/icones/Logo_Redondo_RD_Station.svg";
import { Tipografia } from "../../../../_common";
import { useEvent } from "../../../../hooks";
import LinkLogoRDMentorIA from "../../../../_common/components/LinkLogoRDMentorIA";

function MentorIAOutline({ id, lead, filtro, classes, ...props }) {
  const intl = useIntl();
  const { data: dto, loading, mutate, error } = useSWR(
    `/api/pipeline/LeadProfile/Buscar?leadId=${id}&filtro=${filtro}`
  );
  const conteudo = dto?.resultado || null;

  const exibicaoLimitada = dto?.exibicaoLimitada;

  const [textoExpandido, setTextoExpandido] = useState(false);
  const expandirLabel = textoExpandido
    ? intl.formatMessage({ defaultMessage: "Diminuir" })
    : intl.formatMessage({ defaultMessage: "Expandir" });

  const handleExpandir = () => {
    setTextoExpandido(!textoExpandido);
  };

  const textoParcial = exibicaoLimitada ? conteudo?.slice(0, 150) : conteudo?.slice(0, 300);

  useEvent("magicProfiler/reload", () => {
    mutate();
  });

  return (
    <>
      <Outline
        avatar={<LinkLogoRDMentorIA />}
        form={null}
        initialValues={{ magicWrite: conteudo }}
        editButtonId="btn-edit-mentorIA"
        id="card-MentorIA"
        {...props}
      >
        <>
          {!loading &&
            conteudo && (
              <>
                <Grid className={`${classes.conteudo} ${!textoExpandido ? classes.addDegrade : ""}`}>
                  <MentorIA texto={textoExpandido ? conteudo : textoParcial} />
                </Grid>
                <Grid item xs={12}>
                  <span>
                    <Button
                      className={classes.btnGrid}
                      color="primary"
                      id="buttonExpandir"
                      onClick={handleExpandir}
                      disabled={exibicaoLimitada}
                    >
                      {expandirLabel}
                    </Button>
                  </span>
                </Grid>
              </>
            )}
          {!loading &&
            !conteudo && (
              <>
                {error && (
                  <Tipografia cor="darkSecondaryText" italico style={{ padding: 20 }}>
                    {intl.formatMessage({ defaultMessage: "Ocorreu um erro na consulta à Mentor IA" })}
                  </Tipografia>
                )}
                {!error && (
                  <Grid style={{ textAlign: "center" }}>
                    <img
                      src={iconeCarregando}
                      alt="carregamento MagicWrite"
                      width="50"
                      height="50"
                      style={{ opacity: "0.6" }}
                    />
                  </Grid>
                )}
              </>
            )}
        </>
      </Outline>
    </>
  );
}

MentorIAOutline.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  filtro: PropTypes.bool,
  classes: PropTypes.object,
  lead: PropTypes.object,
};

export default withStyles({
  conteudo: {
    whiteSpace: "pre-line",
    textAlign: "justify",
  },
  addDegrade: {
    textAlign: "justify",
    position: "relative",
    overflow: "hidden",
    backgroundImage: "linear-gradient(to bottom, black, white)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  btnGrid: {
    paddingTop: 16,
    paddingLeft: 20,
  },
  settingsIcon: {
    color: "#9e9e9e !important",
    "&:hover": {
      color: "#212121 !important",
    },
  },
})(MentorIAOutline);
