import React from "react";
import PropTypes from "prop-types";
import useSWR from "swr";
import axios from "axios";
import { useIntl } from "react-intl";
import { Field, Form, Formik } from "formik";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { RadioGroup } from "formik-material-ui";

ModalEscolherQuestionario.propTypes = {
  leadId: PropTypes.number,
  onClose: PropTypes.func,
  questionariosPreCarregados: PropTypes.array,
  esconderBotaoCancelar: PropTypes.bool,
  isPublic: PropTypes.bool,
};
export default function ModalEscolherQuestionario({
  leadId,
  onClose,
  questionariosPreCarregados = null,
  esconderBotaoCancelar = false,
  isPublic,
}) {
  const intl = useIntl();
  const { data: questionarios, isValidating } = useSWR(`/api/pipeline/lead/BuscarQuestionariosPossiveis?id=${leadId}`, {
    dedupingInterval: 0,
    fetcher: (...args) =>
      questionariosPreCarregados !== null ? questionariosPreCarregados : axios.get(...args).then(resp => resp.data),
  });

  if (!questionarios || isValidating) return null;

  const encontrarTipoQuestionario = id => {
    const idQuestionario = typeof id === "number" ? id : Number(id);
    const questionario = !isPublic
      ? questionarios.find(q => q.questionario.id === idQuestionario)
      : questionarios.find(q => q.id === idQuestionario);
    return questionario ? questionario.tipoQuestionario : null;
  };

  function normalizarQuestionarios() {
    return questionarios.map(questionario => questionario.questionario ?? questionario);
  }

  return (
    <Formik
      initialValues={{ questionario: String(normalizarQuestionarios()[0]?.id) }}
      onReset={() => onClose()}
      onSubmit={v => onClose(v.questionario, isPublic ? null : encontrarTipoQuestionario(v.questionario))}
    >
      <Form>
        <DialogTitle>{intl.formatMessage({ defaultMessage: "Seleção de questionário" })}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {intl.formatMessage({
              defaultMessage: "Os seguintes questionários estão disponíveis para esse lead",
            })}
          </DialogContentText>
          <Field component={RadioGroup} name="questionario">
            {normalizarQuestionarios().map(questionario => (
              <FormControlLabel
                key={questionario?.id}
                value={`${questionario?.id}`}
                id={`opcao-questionario-${questionario?.id}`}
                control={<Radio />}
                label={questionario?.nome}
              />
            ))}
          </Field>
        </DialogContent>
        <DialogActions>
          {!esconderBotaoCancelar && (
            <Button type="reset" id="button-escolher-questionario-cancelar">
              {intl.formatMessage({ defaultMessage: "Cancelar" })}
            </Button>
          )}
          <Button type="submit" color="primary" id="button-escolher-questionario-confirmar">
            {intl.formatMessage({ defaultMessage: "Confirmar" })}
          </Button>
        </DialogActions>
      </Form>
    </Formik>
  );
}
