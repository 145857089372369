import React from "react";
import PropTypes from "prop-types";
import ArticleIcon from "@mui/icons-material/Article";

import { useIntl } from "react-intl";
import { ActionButton } from "../../../../components";

function ResumoLeadIA({ id, setResumoLeadIAIsOpen, ...props }) {
  const intl = useIntl();

  const handleClickAbrirModalResumoLeadIA = () => {
    setResumoLeadIAIsOpen(true);
  };

  return (
    <>
      <ActionButton
        id="btn-ResumoLeadIA"
        onClick={() => handleClickAbrirModalResumoLeadIA()}
        type="icon"
        {...props}
        icon={<ArticleIcon />}
        label={intl.formatMessage({ defaultMessage: "Resumo Lead IA" })}
        tooltip={intl.formatMessage({ defaultMessage: "Resumo Lead IA" })}
      />
    </>
  );
}

ResumoLeadIA.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setResumoLeadIAIsOpen: PropTypes.func,
};

export default ResumoLeadIA;
