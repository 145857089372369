import React from "react";
import { useIntl } from "react-intl";
import AutoComplete from "../../../../_common/AutoComplete/AutoComplete";

function LeadSelectOrganizacao(props) {
  const intl = useIntl();

  return (
    <AutoComplete
      label={intl.formatMessage({ defaultMessage: "Organização" })}
      dataSourceConfig={{ id: "id", text: "nome" }}
      api={debouncedSearch =>
        `/Api/Pipeline/Organizacao/ListarDescricao?descricao=${debouncedSearch}&somenteAtivos=true&take=20`
      }
      {...props}
    />
  );
}

export default LeadSelectOrganizacao;
