import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field, useFormikContext } from "formik";
import { Select, TextField } from "formik-material-ui";
import { MenuItem, withStyles, FormControl, InputLabel, CircularProgress } from "@material-ui/core";
import { get } from "dot-prop-immutable";
import { defineMessage, useIntl } from "react-intl";
import TextMaskTelefone from "../../../../components/TextMaskTelefone";
import { useLeadsSemelhantes, useLeadsSemelhantesBloqueados } from "../../../../hooks";
import LeadsSemelhantes from "../LeadsSemelhantes";
import { CAMPOS_LEADS_SEMELHANTES } from "../../../../_common/constantes";
import { Flex } from "../../../../_common";
import SelectDDI from "./SelectDDI/FormikField";

const getTelSemMask = numero =>
  numero
    .toString()
    .replace(/\D/g, "")
    .trim();

function validateTelefone(ddi, numero, required, semelhanteBloqueado) {
  if (!numero && required) return "Campo obrigatório *";
  if (semelhanteBloqueado) return "Contato bloqueado.";
  if (!numero) return "";

  const telSemMask = getTelSemMask(numero);
  const regexp = /^\d{8,}$/g;
  const numeroValido = regexp.test(telSemMask);

  if (!numeroValido) return "Número inválido";

  return "";
}

const tiposDeTelefone = [
  { id: 1, descricao: defineMessage({ defaultMessage: "Comercial" }) },
  { id: 2, descricao: defineMessage({ defaultMessage: "Celular" }) },
];
function Telefone({
  name,
  index,
  leadId,
  campoSemelhantes = CAMPOS_LEADS_SEMELHANTES.LEAD_TELEFONE,
  campoSemelhantesBloqueados = CAMPOS_LEADS_SEMELHANTES.CONTATO_TELEFONE,
  deveBuscarSemelhantes = true,
  required = false,
  disabled = false,
  classes,
}) {
  const { values, errors, validateField } = useFormikContext();
  const path = `${name}.${index}`;
  const numeroPath = `${path}.numero`;
  const ddi = get(values, `${path}.ddi`, "");
  const numero = get(values, `${path}.numero`, "");
  const error = get(errors, numeroPath);
  const hasError = !!error;
  const intl = useIntl();

  const [telefonePesquisa, setTelefonePesquisa] = useState("");

  const [semelhantes, loading] = useLeadsSemelhantes(
    campoSemelhantes,
    deveBuscarSemelhantes && telefonePesquisa,
    leadId,
    ddi
  );
  const { data: temSemelhanteBloqueado, isValidating: loadingBloqueados } = useLeadsSemelhantesBloqueados(
    campoSemelhantesBloqueados,
    deveBuscarSemelhantes && telefonePesquisa,
    null,
    ddi
  );

  const getValorNumeroInput = () => {
    // É necessário buscar o valor no DOM devido a inconsistencias do Formik
    const numeroInput = get(document.getElementsByName(numeroPath), "0");
    return numeroInput?.value;
  };

  const atualizarTelefonePesquisa = ddiValue => {
    const telSemMask = getTelSemMask(getValorNumeroInput());
    setTelefonePesquisa(`${ddiValue}${telSemMask}`);
  };

  const helperText = () => {
    if (semelhantes.quantidade) return <LeadsSemelhantes {...semelhantes} />;
    return `${hasError ? error : ""}`;
  };

  React.useEffect(
    () => {
      if (!loadingBloqueados) {
        validateField(numeroPath);
      }
    },
    [telefonePesquisa, loadingBloqueados, temSemelhanteBloqueado]
  );

  return (
    <>
      <Flex>
        <Field
          name={`${path}.ddi`}
          component={SelectDDI}
          InputProps={{
            className: classes.selectDDI,
            onBlur: ddiValue => atualizarTelefonePesquisa(ddiValue),
            inputProps: { numero },
          }}
          disabled={disabled}
        />
        <Field
          name={`${path}.numero`}
          label={`${intl.formatMessage({ defaultMessage: "Telefone" })} ${index + 1}`}
          InputProps={{
            endAdornment: loading ? <CircularProgress color="inherit" size={20} /> : undefined,
            error: semelhantes.quantidade || hasError,
            onBlur: () => {
              atualizarTelefonePesquisa(ddi);
            },
            inputComponent: TextMaskTelefone,
            inputProps: { ddi },
          }}
          InputLabelProps={{ error: semelhantes.quantidade || hasError }}
          FormHelperTextProps={{ error: semelhantes.quantidade || hasError }}
          component={TextField}
          validate={val => validateTelefone(ddi, val, required, temSemelhanteBloqueado)}
          style={{ flexGrow: 1, marginRight: 20 }}
          error={hasError}
          helperText={helperText()}
          disabled={disabled}
        />
      </Flex>

      <FormControl className={classes.input}>
        <InputLabel>{intl.formatMessage({ defaultMessage: "Marcador" })}</InputLabel>
        <Field name={`${path}.tipo.id`} component={Select} disabled={disabled}>
          {tiposDeTelefone.map(({ id: idT, descricao }) => (
            <MenuItem value={idT} key={idT}>
              {intl.formatMessage(descricao)}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
    </>
  );
}

Telefone.propTypes = {
  name: PropTypes.string,
  index: PropTypes.number,
  campoSemelhantes: PropTypes.oneOf(Object.keys(CAMPOS_LEADS_SEMELHANTES)),
  campoSemelhantesBloqueados: PropTypes.oneOf(Object.keys(CAMPOS_LEADS_SEMELHANTES)),
  deveBuscarSemelhantes: PropTypes.bool,
  required: PropTypes.bool,
  leadId: PropTypes.number,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
};

export default withStyles({
  input: {
    flexGrow: 1,
    width: 144,
    "&:first-child": { marginRight: 24 },
  },
  selectDDI: { position: "relative", top: 9 },
})(Telefone);
