import React, { useState, useEffect } from "react";
import { useIntl, defineMessage } from "react-intl";
import PropTypes from "prop-types";
import axios from "axios";
import useSWR from "swr";
import { DialogContent } from "@material-ui/core";
import Outline from "../Outline";
import {
  Loading,
  Tipografia,
  createDialogConfirm,
  createSnackbarAPIException,
  ajaxBlackout,
  Flex,
} from "../../../../_common";
import OrganizacaoInfo from "../../../../components/Organizacao/Info/Info";
import { ActionButton } from "../../../../components";
import OrganizacaoEditForm from "../../../../components/Organizacao/EditForm/EditForm";
import { GET_INITIAL_VALUES as GET_ORG_INITIAL_VALUES } from "../../../../components/Organizacao/CardEdit/CardEdit";
// TODO: ver se o log na timeline será feito quando alterar a Org
// import {
//   // triggerReloadTimelineLog,
// } from "../../../store/reducers/detalhesLead/detalhesLeadActions";

function retirarMascara(numero) {
  return numero
    .toString()
    .replace(/[( )-]+/g, "")
    .trim();
}

OrganizacaoOutline.propTypes = {
  lead: PropTypes.object,
  classes: PropTypes.object,
  atualizar: PropTypes.func,
  buscarLista: PropTypes.func,
  protecaoDados: PropTypes.bool,
};

const messages = {
  dialogTitle: defineMessage({ defaultMessage: "Desvincular Organização" }),
  dialogText: defineMessage({
    defaultMessage:
      "Tem certeza que deseja desvincular {leadName} (Lead) de <b>{orgName}</b>?{br}{br}A {orgName} continuará existindo, mas não estará mais vinculada a essa oportunidade.",
  }),
  dialogAcceptLabel: defineMessage({ defaultMessage: "Desvincular" }),
};

function OrganizacaoOutline({ lead, ...props }) {
  const intl = useIntl();
  const [orgIdEdit, setOrgIdEdit] = useState(lead?.organizacaoId);
  const { data: org, isValidating: isLoadingOrg, mutate: mutateOrg } = useSWR(
    orgIdEdit ? `/api/pipeline/Organizacao/Buscar?id=${orgIdEdit}` : null
  );
  const [isNovoVinculo, setIsNovoVinculo] = useState(false);

  const [initialValues, setInitialValues] = useState(GET_ORG_INITIAL_VALUES());

  useEffect(
    () => {
      setInitialValues(GET_ORG_INITIAL_VALUES(org));
    },
    [org]
  );

  const desvincularOrganizacao = async () => {
    ajaxBlackout.show();
    try {
      await axios.post(`/api/pipeline/lead/DesvincularOrganizacao?id=${lead.id}`);
      setIsNovoVinculo(false);
      setOrgIdEdit(null);
      mutateOrg(null, false);
      document.dispatchEvent(new Event("outline/org-disable-edit"));
      document.dispatchEvent(new Event("detalhe-lead/painel/reload"));
    } catch (err) {
      createSnackbarAPIException(err);
    } finally {
      ajaxBlackout.hide();
    }
  };

  const onClickDesvincular = () => {
    createDialogConfirm({
      title: messages.dialogTitle,
      content: (
        <DialogContent>
          {intl.formatMessage(messages.dialogText, {
            leadName: lead?.nome,
            orgName: org?.nome,
            br: <br />,
          })}
        </DialogContent>
      ),
      acceptLabel: messages.dialogAcceptLabel,
      callback: accepted => accepted && desvincularOrganizacao(lead.id),
    });
  };

  const onClickVincularSemelhante = async (orgSemelhanteId, closeModal) => {
    try {
      ajaxBlackout.show();
      await axios.post(`/api/pipeline/lead/VincularOrganizacao`, {
        leadId: lead.id,
        organizacaoId: orgSemelhanteId,
      });
      closeModal();
      setOrgIdEdit(orgSemelhanteId);
      mutateOrg();
      document.dispatchEvent(new Event("outline/org-disable-edit"));
      document.dispatchEvent(new Event("detalhe-lead/painel/reload"));
    } catch (err) {
      createSnackbarAPIException(err);
    } finally {
      ajaxBlackout.hide();
    }
  };

  const cadastrarVincular = async values => {
    let success = true;

    ajaxBlackout.show();
    try {
      const { data } = await axios.post(`/api/pipeline/organizacao/CadastrarVincular?leadId=${lead.id}`, values);
      setIsNovoVinculo(false);
      setOrgIdEdit(data);
      document.dispatchEvent(new Event("detalhe-lead/painel/reload"));
    } catch (err) {
      success = false;
      createSnackbarAPIException(err);
    } finally {
      ajaxBlackout.hide();
    }

    return Promise.resolve(success);
  };

  const editar = async values => {
    let success = true;
    try {
      const { data } = await axios.post(`/api/pipeline/organizacao/Salvar`, values);
      setIsNovoVinculo(false);
      setOrgIdEdit(data);
      mutateOrg();
      document.dispatchEvent(new Event("detalhe-lead/painel/reload"));
    } catch (err) {
      success = false;
      createSnackbarAPIException(err);
    } finally {
      ajaxBlackout.hide();
    }

    return Promise.resolve(success);
  };

  const onSubmit = async values => {
    const organizacao = {
      ...values,
      cpfCnpj: values.cpfCnpj?.replace(/\D/g, ""),
      telefones: values.telefones
        .filter(telefone => telefone.numero)
        .map(tel => ({ ...tel, numero: retirarMascara(tel.numero) })),
    };

    if (!org?.id) {
      return cadastrarVincular(organizacao);
    }

    return editar(organizacao);
  };

  return (
    <Outline
      {...props}
      title={intl.formatMessage({
        defaultMessage: "Empresa/Organização",
      })}
      form={OrganizacaoEditForm}
      action={!org?.id ? <span /> : null}
      initialValues={initialValues}
      onSubmit={onSubmit}
      orgId={org?.id}
      onClickDesvincular={onClickDesvincular}
      onClickVincularSemelhante={lead?.organizacaoId ? null : onClickVincularSemelhante}
      editButtonId="btn-editar-organizacao"
      forceEdit={isNovoVinculo}
      onCancel={() => setIsNovoVinculo(false)}
      disableEditEventName="outline/org-disable-edit"
      autoFillLeadId={lead?.id}
      id="card-Organizacao"
    >
      <Loading isLoading={isLoadingOrg}>
        {org ? (
          <OrganizacaoInfo org={org} />
        ) : (
          <Flex flexDirection="column" alignItems="flex-start">
            <Tipografia cor="darkSecondaryText" italico style={{ paddingLeft: 20 }}>
              {intl.formatMessage({ defaultMessage: "Nenhuma organização vinculada ao Lead/Oportunidade" })}
            </Tipografia>
            <ActionButton
              id="btn-buscar-contatos"
              type="text"
              color="primary"
              label={intl.formatMessage({
                defaultMessage: "Vincular organização",
              })}
              onClick={() => setIsNovoVinculo(true)}
            />
          </Flex>
        )}
      </Loading>
    </Outline>
  );
}

export default OrganizacaoOutline;
