import React from "react";
import PropTypes from "prop-types";
import useSWR from "swr";
import { Dialog } from "@material-ui/core";
import { useIntl } from "react-intl";
import axios from "axios";
import SvgIcon from "@material-ui/core/SvgIcon";

import { useUsuario } from "../../../../hooks";
import concluirAgendamento from "../concluirAgendamento";
import ArrowRightLeft from "../../../../_common/icones/ArrowRightLeft";
import { ActionButton } from "../../../../components";
import ModalPassarManualmente from "./ModalPassarManualmente";
import { TIPO_GATE, PERMISSAO_ETAPA, SITUACAO_REUNIAO, CONCLUIR_REUNIAO } from "../../../../_common/constantes";
import { AjaxBlackout, createSnackbar, createSnackbarAPIException } from "../../../../_common";
import usePermissaoEtapa from "../../../base-leads/pipeline/components/usePermissaoEtapa";

PassarManualmente.propTypes = {
  onTransfer: PropTypes.func,
  etapaLead: PropTypes.object,
  lead: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    grupos: PropTypes.arrayOf(PropTypes.number),
    preVendedorId: PropTypes.number,
    agendamento: PropTypes.object,
    etapa: PropTypes.shape({
      tipoGate: PropTypes.number,
      gateAnterior: PropTypes.number,
      id: PropTypes.number.isRequired,
      funilId: PropTypes.number,
      proximaEtapaId: PropTypes.number,
      funil: PropTypes.object,
    }),
    reuniao: PropTypes.object,
    situacao: PropTypes.number,
  }),
};

const possuiPassagemManual = etapa =>
  etapa?.gateAnterior === TIPO_GATE.MANUAL || (etapa?.proximaEtapaId && etapa?.tipoGate === TIPO_GATE.MANUAL);

function usePainelPermissao(lead) {
  const passagemManual = possuiPassagemManual(lead.etapa);

  const { data: painel, mutate: mutatePainel } = useSWR(
    passagemManual ? `/api/pipeline/lead/painel?id=${lead.id}` : null,
    {
      fallbackData: lead?.etapa?.id ? lead : undefined,
      revalidateOnMount: !lead?.etapa?.id,
    }
  );

  const permissao = usePermissaoEtapa(lead?.etapa);

  const mutate = () => {
    mutatePainel();
  };

  return [painel, permissao, mutate];
}

function PassarManualmente({ lead, etapaLead, onTransfer = x => x, ...props }) {
  const intl = useIntl();
  const passagemManual = possuiPassagemManual(lead.etapa);
  const funilId = lead.etapa?.funil ? lead.etapa.funil.id : lead.etapa?.funilId;
  const url = `/api/pipeline/etapa/listar?funilid=${funilId}`;
  const { data: etapas } = useSWR(() => (passagemManual ? url : null));
  const { isGerente } = useUsuario();
  const [painel, permissaoNaEtapa] = usePainelPermissao(lead);
  const hasPermission = isGerente || permissaoNaEtapa === PERMISSAO_ETAPA.INTERAGIR;
  const reuniaoId = lead.reuniao?.id || lead.agendamento?.id;
  const reuniaoSituacao = lead.reuniao?.situacao || lead.agendamento?.situacao;
  const [modalOpen, setModalOpen] = React.useState(false);
  const [anterior, posterior] = React.useMemo(
    () => {
      if (!etapas || !painel?.etapa) return [];

      const etapaAnterior = {
        ...etapas.find(etapa => etapa.proximaEtapaId === lead.etapa?.id),
        podeMover: lead.etapa?.gateAnterior === TIPO_GATE.MANUAL,
      };

      const etapaPosterior = {
        ...etapas.find(etapa => etapa.id === lead.etapa?.proximaEtapaId),
        podeMover: lead.etapa?.tipoGate === TIPO_GATE.MANUAL,
      };

      return [etapaAnterior, etapaPosterior];
    },
    [etapas, painel, lead]
  );

  function close(etapa) {
    if (!etapa) {
      onTransfer(false);
      setModalOpen(false);
    }
    // Verifica se possui reunião E se essa reunião NÃO está concluída E se essa reunião NÃO está cancelada
    else if (
      reuniaoId &&
      reuniaoSituacao !== SITUACAO_REUNIAO.CONCLUIDO &&
      reuniaoSituacao !== SITUACAO_REUNIAO.CANCELADA
    )
      concluirAgendamento(
        reuniaoId,
        lead.id,
        CONCLUIR_REUNIAO.MOVER_MANUALMENTE,
        intl.formatMessage({
          defaultMessage: "Para realizar a mudança de etapa é necessário concluir a reunião atual. Deseja continuar?",
        }),
        () => moverManualmente(etapa)
      );
    else moverManualmente(etapa);
  }

  async function moverManualmente(etapa) {
    AjaxBlackout.Show();
    try {
      const { data } = await axios.post("/api/pipeline/lead/movermanualmente", {
        id: lead.id,
        etapaOrigemId: lead.etapa.id,
        etapaDestinoId: etapa,
      });
      if (!data.success) throw data.messages;
      onTransfer(etapa);
      createSnackbar(intl.formatMessage({ defaultMessage: "Lead movido com sucesso" }));
    } catch (err) {
      onTransfer(false);
      createSnackbarAPIException(err);
    } finally {
      AjaxBlackout.Hide();
      setModalOpen(false);
    }
  }

  if (
    !hasPermission ||
    (!anterior?.podeMover && !posterior?.podeMover) ||
    !possuiPassagemManual(lead.etapa) ||
    !lead.situacao
  )
    return null;

  return (
    <>
      <ActionButton
        id="btnPassarManualmente"
        type="text"
        icon={
          <SvgIcon>
            <ArrowRightLeft />
          </SvgIcon>
        }
        variant="contained"
        color="primary"
        tooltip={intl.formatMessage({ defaultMessage: "Mover lead" })}
        label={intl.formatMessage({ defaultMessage: "Mover lead" })}
        onClick={() => setModalOpen(true)}
        {...props}
      />
      <Dialog open={modalOpen} onClose={() => close(false)}>
        <ModalPassarManualmente posterior={posterior} anterior={anterior} onTransfer={close} />
      </Dialog>
    </>
  );
}

export default PassarManualmente;
