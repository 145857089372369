import React from "react";
import PropTypes from "prop-types";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Radio,
  Tooltip,
  withStyles,
  FormControlLabel,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { Flex, Loading } from "../../../../_common";

ModalPassarManualmente.propTypes = {
  anterior: PropTypes.shape({ id: PropTypes.number, nome: PropTypes.string, podeMover: PropTypes.bool }),
  posterior: PropTypes.shape({ id: PropTypes.number, nome: PropTypes.string, podeMover: PropTypes.bool }),
  onTransfer: PropTypes.func.isRequired,
  classes: PropTypes.object,
};

function ModalPassarManualmente({ anterior, posterior, onTransfer, classes }) {
  const [etapa, setEtapa] = React.useState();
  const intl = useIntl();

  return (
    <>
      <DialogTitle> {intl.formatMessage({ defaultMessage: "Mover Lead" })}</DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText className={classes.contentText}>
          {intl.formatMessage({ defaultMessage: "Selecione a etapa destino do lead" })}
        </DialogContentText>
        <Loading isLoading={!anterior && !posterior} className={classes.loading}>
          {(anterior || posterior) && (
            <div className={classes.container}>
              {anterior.id && (
                <Tooltip
                  title={intl.formatMessage({
                    defaultMessage: "Só é possível mover leads entre etapas manuais.",
                  })}
                  placement="right-start"
                  disableHoverListener={anterior.podeMover}
                  disableFocusListener={anterior.podeMover}
                >
                  <Flex justifyContent="flex-start" alignItems="center" className={classes.flex}>
                    <FormControlLabel
                      disabled={!anterior.podeMover}
                      value={anterior.id?.toString()}
                      control={<Radio />}
                      label={anterior.nome}
                      checked={etapa === anterior.id?.toString()}
                      onChange={event => setEtapa(event.target.value)}
                    />
                  </Flex>
                </Tooltip>
              )}

              {posterior.id && (
                <Tooltip
                  title={intl.formatMessage({
                    defaultMessage: "Só é possível mover leads entre etapas manuais.",
                  })}
                  placement="right-start"
                  disableHoverListener={posterior.podeMover}
                  disableFocusListener={posterior.podeMover}
                >
                  <Flex justifyContent="flex-start" alignItems="center" className={classes.flex}>
                    <FormControlLabel
                      disabled={!posterior.podeMover}
                      value={posterior.id?.toString()}
                      control={<Radio />}
                      label={posterior.nome}
                      checked={etapa === posterior.id?.toString()}
                      onChange={event => setEtapa(event.target.value)}
                    />
                  </Flex>
                </Tooltip>
              )}
            </div>
          )}
        </Loading>
      </DialogContent>
      <DialogActions>
        <Button id="btnCancelar-ModalPassarManualmente" onClick={() => onTransfer()}>
          {intl.formatMessage({ defaultMessage: "Cancelar" })}
        </Button>
        <Button
          id="btnMover-ModalPassarManualmente"
          color="primary"
          onClick={() => onTransfer(etapa)}
          disabled={!etapa}
        >
          {intl.formatMessage({ defaultMessage: "Mover" })}
        </Button>
      </DialogActions>
    </>
  );
}

export default withStyles(() => ({
  content: {
    paddingLeft: 0,
  },
  contentText: {
    paddingLeft: 24,
    marginBottom: 12,
  },
  container: {
    paddingLeft: 24,
  },
  loading: {
    maxHeight: "100px",
  },
}))(ModalPassarManualmente);
