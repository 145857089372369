import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Dialog } from "@material-ui/core";
import ContactMail from "@material-ui/icons/ContactMail";
import { connect } from "react-redux";

import { useIntl } from "react-intl";
import ModalReenviarEmailFeedback from "./ModalReenviarEmailFeedback";
import { ActionButton } from "../../../../components";
import permissions from "../../../../_common/permissions";
import { TIPO_QUESTIONARIO, SITUACAO_REUNIAO } from "../../../../_common/constantes";

ReenviarEmailFeedback.propTypes = {
  callback: PropTypes.func,
  children: PropTypes.func,
  claim: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  leads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      grupos: PropTypes.arrayOf(PropTypes.number),
      preVendedorId: PropTypes.number,
      reuniaoId: PropTypes.number,
    })
  ),
  permissao: PropTypes.bool,
};

function ReenviarEmailFeedback({ callback = () => null, children, leads = [], permissao, ...props }) {
  const intl = useIntl();
  const [modalOpen, setModalOpen] = useState(false);

  if (
    leads.some(
      lead =>
        lead.reuniao &&
        (lead.reuniao.situacao !== SITUACAO_REUNIAO.VIGENTE && lead.reuniao.situacao !== SITUACAO_REUNIAO.REAGENDADA)
    ) ||
    (!leads.some(lead => lead.reuniao?.id) &&
      leads.some(
        lead => !lead.reuniao?.id || lead.etapa?.questionario?.tipo !== TIPO_QUESTIONARIO.FEEDBACK || !permissao
      ))
  ) {
    return null;
  }

  const close = response => {
    setModalOpen(false);
    callback(response);
  };

  return (
    <Fragment>
      <ActionButton
        id="btn-reenviar-feedback"
        type="menu"
        icon={<ContactMail />}
        label={intl.formatMessage({ defaultMessage: "Reenviar E-mail de Feedback" })}
        onClick={() => setModalOpen(true)}
        claim={permissions.REENVIAR_EMAIL_FEEDBACK}
        leads={leads}
        {...props}
      />
      <Dialog open={modalOpen} onClose={() => close(false)}>
        <ModalReenviarEmailFeedback ids={leads.map(lead => lead.id ?? lead.lead.id)} {...props} callback={close} />
      </Dialog>
    </Fragment>
  );
}

const mapStateToProps = state => ({
  permissao: state.usuario.enviarEmailFeedbackVendedor,
});

export default connect(mapStateToProps)(ReenviarEmailFeedback);
export * from "./ModalReenviarEmailFeedback";
